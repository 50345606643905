import api from "./api"
import Vuetify from '../plugins/vuetify'

export default {

  initialized: false,
  user: null,

  setTheme () {
    if (localStorage.theme){
          if( localStorage.theme == 'false')
          Vuetify.framework.theme.dark = false
          if( localStorage.theme == 'true')
          Vuetify.framework.theme.dark = true
    }else{
          Vuetify.framework.theme.dark = false
    }
  },

  // user routes
  async login(username, password) {
    api.accessToken = await api.login (username, password)
    this.initialized = true
    localStorage.aahaaToken = api.accessToken 
    return
  },
  async requestNewPassword(username) {
    return await api.requestNewPassword (username)
  },
  async changePasword(token, password) {
    return await api.changePasword (token, password)
  },
  async findMe() {
    return this.user= await api.findMe()
  },
  async insertNewUser(userInformation) {
    return await api.insertNewUser(userInformation)
  },
  async getUsersList() {
    return await api.getUsersList()
  },
  async getUser(email) {
    return await api.getUser(email)
  },
  async changeCoins(form) {
    return await api.changeCoins(form);
  },
  async ensureInitialized() {
    if (localStorage.aahaaToken){
          api.accessToken = localStorage.aahaaToken
          try {
            await this.findMe()
            return this.initialized = true
          } catch (error) {
            return this.initialized = false
          }
    }
    else{ 
        return false 
    }
  },
  signOut() {
    api.accessToken = null
    localStorage.removeItem('aahaaToken')
    this.initialized = false
  },
  
  // send Email
  async sendEmail(contactForm) {
    return await api.sendEmail(contactForm)
  },
  async sendRegisteringEmail(contactForm) {
    return await api.sendRegisteringEmail(contactForm)
  },
  async sendInformationEmail(emailForm) {
    return await api.sendInformationEmail(emailForm)
  },

  // Job routes
  async getJobs(queries) {
    return  api.getJobs(queries)
  },
  async getJobsAdmin(queries) {
    return  api.getJobsAdmin(queries)
  },
  async getJobAdmin(id) {
    return await api.getJobAdmin(id)
  },
  async insertJob(jobItem) {
    return  api.insertJob(jobItem)
  },
  async updateJob(jobItem) {
    return  api.updateJob(jobItem)
  }, 
  async deleteJob(job_id) {
    return  api.deleteJob(job_id)
  },
  async publishJob(jobItem) {
    return await api.publishJob(jobItem)
  },
  async makeJobPublic(job_id) {
    return await api.makeJobPublic(job_id)
  },
  async makeJobHidden(job_id) {
    return await api.makeJobHidden(job_id)
  },
  async deleteExpiredJobs(time) {
    return await api.deleteExpiredJobs(time)
  },

  // Items routes
  async insertItem(item, file) {
    return await api.insertItem(item, file)
  },
  async getItems(queries) {
    return await api.getItems(queries)
  },
  async getItem(id) {
    return await api.getItem(id)
  },
  async getUserItems() {
    return await api.getUserItems()
  },
  async deleteItem(item_id) {
    return await api.deleteItem(item_id)
  },
  async updateItem(feedback_id) {
    return await api.updateItem(feedback_id)
  },

  // Get image of a specific item
  async getImage(item_id) {
      return await api.getImage(item_id)
  },

  // like routes
  async getUserLikes() {
    return  await api.getUserLikes()
  },
  async getLike(item_id) {
    return  await api.getLike(item_id)
  },
  async insertLike(item_id) {
    return  await api.insertLike(item_id)
  },
  async deleteLike(item_id) {
    return  await api.deleteLike(item_id)
  },

  // Request routes
  async getRequests() {
    return  await api.getRequests();
  },
  async getUserRequests(email) {
    return  await api.getUserRequests(email)
  },
  async deleteRequest(id) {
    return  await api.deleteRequest(id)
  },

  // Admin routes for Items
  async getItemsAdmin(queries) {
    return await api.getItemsAdmin(queries)
  },
  async getItemAdmin(item_id) {
    return await api.getItemAdmin(item_id)
  },
  async publishItem(item) {
    return await api.publishItem(item)
  },
  async makeItemPublic(item_id) {
    return await api.makeItemPublic(item_id)
  },
  async makeItemHidden(item_id) {
    return await api.makeItemHidden(item_id)
  },
  async deleteItemAdmin(item_id) {
    return await api.deleteItemAdmin(item_id)
  },
  async remindRevisions(time) {
    return  api.remindRevisions(time)
  },

  // Crawler routes
  async getNewJobs(id, urlsList) {
    return await api.getNewJobs(id, urlsList)
  },
  

  // Counter
  async getNum(type) {
    return await api.getNum(type);
  },

}


<template>
  <div>
    <v-btn 
          @click="isSIgnOutDialogOpen= true"
          plain
          class="mx-auto" 
          large>Sign Out
    </v-btn>
    <v-dialog 
        v-model="isSIgnOutDialogOpen" 
        overlay-opacity="0.9" 
        persistent 
        width="600">
        <v-toolbar 
            class="text-center">
            <!-- Erroe -->
            <h3 v-if="error"  class="error--text text-center my-1">Error: {{error}}</h3>
            <h3>Quit the app?</h3>
            <v-spacer></v-spacer>
            <v-btn  
                color="primary lighten-1"
                class="mr-2"
                @click="signOut"
                small>Yes
            </v-btn>
            <v-btn  
                color="primary lighten-1"  
                @click="cancel"
                small>No
            </v-btn>
        </v-toolbar>
    </v-dialog>
  </div>
</template>

<script>
import serverGateway from '../modules/serverGateway'
export default {
  name: 'SignOutDialog',
  data() {
    return {
        isSIgnOutDialogOpen: false,
        error: null,
    }
  },
  methods: {

    async signOut() {
      this.loadingflag = true
      try {
          await serverGateway.signOut()
          this.error= null
          this.isSIgnOutDialogOpen= false
          this.$router.push('/login')
      } catch (error) {
          this.error     = error
      }
      this.loadingflag = false
    },

    cancel(){
      this.isSIgnOutDialogOpen= false
    }

  },

}
</script>
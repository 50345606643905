<template>
  <div>
    <v-dialog 
        v-model="isCorrectionDialogOpen" 
        overlay-opacity="0.9"
        persistent 
        width="900">
        <v-toolbar
              class="white--text" 
              height="40">
                <!-- Send -->
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    @click="blurItems()" 
                    :loading="isInformationEmailSending"
                    class="primary"
                    small
                    v-bind="attrs"
                    v-on="on">
                    <v-icon small class="white--text">mdi-send</v-icon>
                </v-btn>
                </template>
                <span>Send Email</span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <!-- close -->
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    @click="closeDialog()" 
                    :disabled="isInformationEmailSending"
                    class="primary"
                    small
                    v-bind="attrs"
                    v-on="on">
                    <v-icon small class="white--text">mdi-close</v-icon>
                </v-btn>
                </template>
                <span>Close</span>
                </v-tooltip>
        </v-toolbar>
        <v-card 
            class="text-center py-2 px-4">
            <div v-for="item in selectedFields" v-bind:key="item.header">
            <v-layout wrap>
              <v-flex xs12 sm4 align-self-center>
                <v-checkbox
                  v-model="item.active"
                  :label="item.header"
                  :disabled="isInformationEmailSending"
                  class="my-3"
                  hide-details
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm8 align-self-center>
                <v-fade-transition>
                <v-combobox
                  v-if="item.active"
                  v-model="item.text"
                  :label="'Write about ' + item.header"
                  :disabled="isInformationEmailSending"
                  :items="item.textOptions"
                  small-chips
                  clearable
                  outlined
                  dense
                  rounded
                  hide-details>
                </v-combobox>
                </v-fade-transition>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            </div>
        </v-card>
    </v-dialog>

    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import snackbar       from  './snackbar.vue'
import serverGateway  from  '../modules/serverGateway'
import globalFunctions from '../modules/globalFunctions'
export default {
  name: 'correctionDialog',
  components: { snackbar },
  props: ['value', 'selectedItem'],
  data() {
    return {
        isCorrectionDialogOpen: this.value,
        isInformationEmailSending: false,
        error: null,
        snacktext: 'Please wait...', 
        snackbar: false,
        selectedFields: globalFunctions.revisionFields
    }
  },

  methods: {

    blurItems(){ 
        setTimeout(()=>{
            this.requestCorrection();
        }, 300);
    },

    async requestCorrection() {
       if(this.selectedFields.length > 0){
            this.isInformationEmailSending = true;
            let fields = '';
            for (let i = 0; i < this.selectedFields.length; i++) {
              if(this.selectedFields[i].active){
                  if(this.selectedFields[i].text){
                    fields =  fields + '<li><strong>' + this.selectedFields[i].header + '</strong>: ' + this.selectedFields[i].text + '</li>';
                  }else{
                    fields =  fields + '<li><strong>' + this.selectedFields[i].header + '</strong></li>';
                  }
              }
            }
            let emailForm = {
                subject: 'Corrections requested',
                product_id: this.selectedItem._id,
                to: this.selectedItem.creator,
                header: 'Please correct information of your product',
                message: `We found some problems in the following fields of your product (${this.selectedItem.title}):<br/>${fields} `
            }
            try {
                this.snacktext = await serverGateway.sendInformationEmail(emailForm);
                this.snackbar  = true;
                this.$emit('runParentFunction');
                this.isInformationEmailSending = false;
                setTimeout(()=>{ this.closeDialog() }, 2000);
            } catch (error) {
                this.error     = error
                this.snacktext = this.error
                this.snackbar  = true 
                this.isInformationEmailSending = false
            }
       }else{
          this.snacktext = 'Please select some fields'
          this.snackbar  = true 
       }
    },

    closeDialog(){
        this.isCorrectionDialogOpen= false
        this.$emit('input', this.isCorrectionDialogOpen)
    },

  },
}
</script>
<template>
  <v-dialog 
      v-model="isUrlEditorOpen" 
      overlay-opacity="0.9" 
      transition="dialog-bottom-transition"
      persistent 
      width="700">
      <v-card
          class="text-center">
          <v-toolbar 
              height="40">
              <strong>{{title}}</strong>
              <v-spacer></v-spacer>
              <!-- Close btn -->
              <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  @click="close()"
                  class="primary"
                  small
                  v-bind="attrs"
                  v-on="on">
                  <v-icon small class="white--text">mdi-check</v-icon>
              </v-btn>
              </template>
              <span>Ok</span>
              </v-tooltip>
        </v-toolbar>
        <!-- editor -->
        <v-form
                v-model="isFormValid"
                ref="form"
                class="pa-4"
                lazy-validation>
                <v-scroll-y-transition group>
                <v-text-field
                        v-for="(url, index) in urlsList" 
                        v-bind:key="index"
                        v-model="urlsList[index]"
                        clearable
                        append-outer-icon="mdi-delete"
                        @click:append-outer="deleteURL(index)"
                        prepend-icon="mdi-open-in-new"
                        @click:prepend="openURL(urlsList[index])"
                        :label="'URL '+ index+1"
                        :rules="textRules"
                        validate-on-blur
                        class="my-3"
                        dense 
                        outlined
                        rounded>
                </v-text-field>
                </v-scroll-y-transition>
        </v-form>
        <!-- Add btn -->
        <v-btn
            @click="urlsList.push('')"
            color="primary"
            class="ma-4 normalBtnText"
            rounded
            small>Add new
            <v-icon small right class="white--text">mdi-plus</v-icon>
        </v-btn>

      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'urlsEditor',
  props: ['value', 'title', 'urlsList'],
  data() {
    return {
      isUrlEditorOpen: this.value,
      textRules: [  v => !!v || 'This field is required' ],
      isFormValid: true,
    }
  },
  methods: {

    deleteURL(index){
      if(index >= 0)  this.urlsList.splice(index, 1)
    },

    openURL(url){
      if(url) window.open(url);
    },

    close(){
      this.$refs.form.validate()
        this.$nextTick(() => {
          if(this.isFormValid){
            this.isUrlEditorOpen= false
            this.$emit('input', this.isUrlEditorOpen)
          }
        })
    }

  }

}
</script>
<template>
  <div>
    <!-- Tabs -->
    <v-tabs
      v-model="tab"
      background-color="primary lighten-1"
      color="white"
      dark
      grow
      icons-and-text>
      <v-tab class="normalBtnText">
        Find academic jobs
        <v-icon>mdi-account-school-outline</v-icon>
      </v-tab>
      <v-tab class="normalBtnText">
        Find industrial jobs
        <v-icon>mdi-account-hard-hat-outline</v-icon>
      </v-tab>
    </v-tabs>
    <!-- Tab items -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
          <CrawlerAcademic/>
      </v-tab-item>
      <v-tab-item>
          <CrawlerIndustrial/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import   CrawlerAcademic    from '../components/jobProviders/CrawlerAcademic.vue'
import   CrawlerIndustrial  from '../components/jobProviders/CrawlerIndustrial.vue'
export default {
  components: { CrawlerAcademic, CrawlerIndustrial },
  name: 'CrawlJobs',
  data () {
      return {
        tab: null,
      }
    },
}
</script>

<template>
  <v-dialog 
      v-model="isYesNoDialogOpen" 
      overlay-opacity="0.9" 
      transition="dialog-bottom-transition"
      persistent 
      width="600">
      <v-toolbar 
          class="text-center">
          <h3>{{YesNoDialogText}}</h3>
          <v-spacer></v-spacer>
          <v-btn  
              @click="runParentFunction()"
              :loading="isLoading"
              color="primary"
              class="mr-2"
              small>
              Yes
          </v-btn>
          <v-btn  
              @click="cancel"
              :disabled="isLoading"
              color="primary"  
              small>
              No
          </v-btn>
      </v-toolbar>
  </v-dialog>
</template>

<script>
export default {
  name: 'yesNoDialog',
  props: ['value', 'YesNoDialogText', 'isLoading'],
  data() {
    return {
        isYesNoDialogOpen: this.value,
    }
  },
  methods: {

    async runParentFunction() {
      this.$emit('runParentFunction')
    },

    cancel(){
      this.isYesNoDialogOpen= false
      this.$emit('input', this.isYesNoDialogOpen)
    }

  },

}
</script>
import Vue from 'vue'
import Router from 'vue-router'
import Home            from './views/Home.vue';
import Login           from './views/Login.vue';
import UserProfile     from './views/UserProfile.vue';
import Products        from './views/Products.vue';
import Jobs            from './views/Jobs.vue';
import Users           from './views/Users.vue';
import Requests        from './views/Requests.vue';
import CrawlJobs       from './views/CrawlJobs.vue';
import NotFound        from './views/NotFound.vue';
import showJobsDialog  from './components/showJobsDialog.vue';
import showItemsDialog from './components/showItemsDialog.vue';
import showUsersDialog from './components/showUsersDialog.vue';

import serverGateway from './modules/serverGateway'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      alias: '/home'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/products',
      name: 'Find what you need!',
      component: Products,
      children: [
        {  path:':modal', name: 'Single product', component: showItemsDialog  }
      ]
    },
    {
      path: '/jobs',
      name: 'Manage jobs',
      component: Jobs,
      children: [
        {  path:':modal', name: 'Single job', component: showJobsDialog  }
      ]
    },
    {
      path: '/users',
      name: 'List of users',
      component: Users,
      children: [
        {  path:':modal', name: 'Single User', component: showUsersDialog  }
      ]
    },
    {
      path: '/requests',
      name: 'List of requests',
      component: Requests
    },
    {
      path: '/crawl-jobs',
      name: 'Add new jobs',
      component: CrawlJobs
    },
    {
      path: '/user-profile',
      name: 'Profile',
      component: UserProfile
    },
    { 
      path: '/404', 
      name: 'notFoundPage',
      component: NotFound
    },  
    { 
      path: '*',
      redirect: '/404'
    }, 
  ]
})

router.beforeEach(async (to, from, next) => {
    if(!serverGateway.initialized) {
      if(to.path !== '/login') {
        next({path:'/login'})
      }else{
        next()
      }
    }
    else {
      next()
    }
}
)

export default router
<template>
  <div>
    <!-- Dialog -->
    <v-dialog
        v-model="isDialogOpen" 
        overlay-opacity="0.9"
        persistent 
        width="1400"
        style="overflow-x: scroll;">
        <v-toolbar
            height="40">

            <!-- Add automatically -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="addAuto = true; addAutomatically()" 
                :disabled="isSaving"
                :loading="addAuto"
                class="primary mr-1"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-autorenew</v-icon>
            </v-btn>
            </template>
            <span>Add automatically</span>
            </v-tooltip>
            <!-- Stop auto adding -->
            <v-tooltip 
                v-if="addAuto" top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                :key="addAuto.toString()+'snackbar'"
                @click="addAuto = false" 
                icon
                :disabled="isSaving"
                class="primary mr-1"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Stop adding automatically</span>
            </v-tooltip>

            <!-- Save btn -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="saveJob()" 
                :loading="isSaving"
                class="primary mr-1"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-cloud-upload-outline</v-icon>
            </v-btn>
            </template>
            <span>Save this job</span>
            </v-tooltip>

            <v-btn
                icon
                @click="goToNextJob()" 
                :disabled="isSaving"
                class="primary mr-1 white--text"
                small>
                No
            </v-btn>
            <v-spacer></v-spacer>
            <!-- Go to previous job -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="goToPreviousJob()" 
                :disabled="isSaving || jobNumber == 0"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-chevron-left</v-icon>
            </v-btn>
            </template>
            <span>Go to previous job</span>
            </v-tooltip>
            <!-- Num jobs -->
            <h3 class="mx-3">{{jobNumber+1}} from {{jobs.length}}</h3>
            <!-- Go to next job -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="goToNextJob()" 
                :disabled="isSaving || jobNumber == jobs.length-1"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-chevron-right</v-icon>
            </v-btn>
            </template>
            <span>Go to next job</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <!-- Cancel adding jobs -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="closeDialog()" 
                :disabled="isSaving"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Cancel</span>
            </v-tooltip>
        </v-toolbar>
        <v-card
            width="100%"
            elevation="30"
            class="mx-auto px-2 py-1">
            <v-slide-x-transition hide-on-leave>
            <v-form
                :key="jobNumber"
                v-model="isFormValid"
                ref="form"
                class="mx-sm-6 pa-sm-2"
                lazy-validation>
                <!-- Title -->
                <span class="px-2">Title:</span>
                <v-text-field
                    v-model="jobs[jobNumber].title"
                    class="px-2"
                    :rules="textRules"
                    outlined
                    dense
                ></v-text-field>
                <!-- Link -->
                <span class="px-2"><a :href="jobs[jobNumber].link" target="_blank">Link:</a></span>
                <v-text-field
                    v-model="jobs[jobNumber].link"
                    class="px-2"
                    :rules="textRules"
                    outlined
                    dense
                    hide-spin-buttons
                ></v-text-field>
                <!-- Other information -->
                <v-layout wrap>
                    <!-- Type -->
                    <v-flex xs12 sm4 class="px-2">
                            <span>Type:</span>
                            <v-select 
                                v-model="jobs[jobNumber].type"
                                :rules="textRules"
                                append-icon="mdi-minus"
                                dense 
                                outlined
                                :items="['Academic', 'Industrial']">
                            </v-select>
                    </v-flex>
                    <!-- Employer -->
                    <v-flex xs12 sm4 class="px-2">
                            <span>Employer:</span>
                            <v-text-field
                                v-model="jobs[jobNumber].provider"
                                :rules="textRules"
                                outlined
                                dense
                            ></v-text-field>
                    </v-flex>
                    <!-- Location -->
                    <v-flex xs12 sm4 class="px-2">
                            <span>Location:</span>
                            <v-text-field
                                v-model="jobs[jobNumber].location"
                                :rules="textRules"
                                outlined
                                dense
                                hide-spin-buttons
                            ></v-text-field>
                    </v-flex>
                    <!-- Salary -->
                    <v-flex xs12 sm4 class="px-2">
                            <span>Salary:</span>
                            <v-text-field
                                v-model="jobs[jobNumber].salary"
                                outlined
                                dense
                                type="number"
                                hide-spin-buttons
                            ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 class="px-2">
                            <!-- Salary unit -->
                            <span>Salary Unit:</span>
                            <v-select 
                                v-model="jobs[jobNumber].salary_unit"
                                :rules="textRules"
                                append-icon="mdi-minus"
                                dense 
                                outlined
                                :items="['USD/hr', 'USD/month', 'USD/year', '€/hr', '€/month', '€/year']">
                            </v-select>
                    </v-flex>
                    <!-- Expires in -->
                    <v-flex xs12 sm4 class="px-2">
                            <div>
                                <span>Expires in:</span>
                                <v-chip v-if="jobs[jobNumber].expires_in == 0"
                                        small
                                        class="mx-2"
                                        color="primary">Unspecified
                                </v-chip>
                                <v-chip v-else 
                                        small
                                        class="mx-2 white--text"
                                        :color="Date.now() > jobs[jobNumber].expires_in ? 'grey' : 'green'">
                                        <span v-if="Date.now() > jobs[jobNumber].expires_in">Job is expired</span>
                                        <span v-else>{{ (new Date(jobs[jobNumber].expires_in)).toLocaleString('en-CA', {hour12: false}) }}</span>
                                </v-chip>
                            </div>
                            <!-- Change deadline -->
                            <v-btn-toggle
                                class="mt-2"
                                rounded>
                                <v-btn 
                                    @click="jobs[jobNumber].expires_in = 0"
                                    :disabled="jobs[jobNumber].expires_in == 0"
                                    small
                                    color="primary"
                                    class="text-capitalize white--text">
                                    Unspecified
                                </v-btn>
                                <v-btn 
                                    @click="isTimePickerDialogOpen = true"
                                    small
                                    color="primary"
                                    class="text-capitalize white--text">
                                    Change
                                </v-btn>
                            </v-btn-toggle>
                    </v-flex>

                </v-layout>
                <!-- description -->
                <span class="px-2">Description: 
                    <v-btn @click="isShowHTMLOpen = true" x-small color="primary">HTML</v-btn>
                </span>
                <v-textarea
                    v-model.trim="jobs[jobNumber].description"
                    class="px-2"
                    :rules="textRules"
                    outlined
                    rows="10"
                ></v-textarea>
            </v-form>
            </v-slide-x-transition>
        </v-card>

        <!-- yesNo Dialog --> 
        <yesNoDialog  v-model="isYesNoDialogOpen" 
                      :key="isYesNoDialogOpen.toString()+'yesNoDialog'" 
                      :YesNoDialogText="'Cancel adding jobs?'" 
                      :isLoading="false" 
                      @runParentFunction="close()" />
        <!-- time Picker Dialog -->
        <timePickerDialog   v-model="isTimePickerDialogOpen" 
                            :currentDate="returnTime(jobs[jobNumber].expires_in)"
                            :key="isTimePickerDialogOpen.toString()+'timepicker'"
                            @runParentFunction="changeDeadline($event)"/>
    </v-dialog>

    <!-- showHTML -->
    <showHTML   v-if="isShowHTMLOpen" 
                v-model="isShowHTMLOpen" 
                :description="jobs[jobNumber].description" 
                :key="isShowHTMLOpen.toString()+'ShowHTML'"/>
    
    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import serverGateway    from  '../../modules/serverGateway';
import snackbar         from  '../snackbar.vue';
import yesNoDialog      from  '../yesNoDialog.vue';
import timePickerDialog from  '../timePickerDialog.vue';
import showHTML         from  './showHTML.vue';
export default {
  name: 'showJobsDialogRegister',
  props: ['value','jobs'],
  components: { snackbar, yesNoDialog, timePickerDialog, showHTML },
  data() {
    return {
        isDialogOpen: this.value,
        isYesNoDialogOpen: false,
        isFormValid: true,
        isSaving: false,
        deadline: null,
        isVisibilityChanging: false,
        isTimePickerDialogOpen: false,
        isShowHTMLOpen: false,
        textRules: [  v => !!v || 'This field is required', ],
        snacktext: 'Please wait...',
        snackbar: false,
        error: null,
        addAuto: false,
        jobNumber: 0,
        keyWords: ['energy', 'geo', 'subsurface', 'mineral', 'petroleum', 'sediment', 'cfd', 'carbon', 'coal', 'gas', 'particle', 'cement', 'drilling', 'resevoir', 'exploration', 'ccs', 'chemi', 'refinery','oil', 
                   'wind', 'turbin', 'charg', 'hydro', 'sediment', 'electr', 'electric', 'battery', 'waste', 'hydrogen', 'biomass', 'geothermal', 'solar']
    }
  },

  methods: {

    closeDialog(){
        if(this.jobs.length == this.jobNumber + 1){
            this.close();
            this.$emit('chengeParentIcon');
        }else{
            this.isYesNoDialogOpen = true;
        }
    },

    close(){
        this.isYesNoDialogOpen = false;
        this.isDialogOpen = false;
        this.$emit('input', this.isDialogOpen);
    },

    changeDeadline(deadline){
        this.jobs[this.jobNumber].expires_in = Date.parse(deadline)
    },

    returnTime(T){
        return (new Date(T).getFullYear()).toString() + '-' + (new Date(T).getMonth()+1).toString() + '-' + (new Date(T).getDate()).toString()         
    },

    reset(){
      this.$refs.form.reset()
    },

    saveJob(){
        this.$refs.form.validate()
        this.$nextTick(() => {
          if(this.isFormValid){
            if(Date.now() > this.jobs[this.jobNumber].expires_in && this.jobs[this.jobNumber].expires_in != 0){
                this.snacktext= 'You can not save an expired job! 😕'
                this.snackbar= true
                setTimeout(() => { this.goToNextJob() }, 400);
            }else{
                this.insertJob() 
            }
          }else{
            this.snacktext= 'Please correct the red fields'
            this.snackbar= true
          }
        })
    },
    
    async insertJob() {
        this.isSaving = true
        try {
            this.snacktext =  await serverGateway.insertJob(this.jobs[this.jobNumber])
            this.snacktext = this.snacktext + ' 😍'
            if(!this.addAuto) setTimeout(() => { this.goToNextJob() }, 400);
            this.snackbar  = true
            this.isSaving = false
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true
            this.isSaving = false
        }
    },

    goToNextJob(){
        if(this.jobs.length > this.jobNumber + 1){
            this.jobNumber += 1
            if(this.addAuto) this.addAutomatically()
        }else{
            this.addAuto = false
        }
    },

    goToPreviousJob(){
        if(this.jobNumber > 0)   this.jobNumber -= 1
    },

    async addAutomatically(){
        let hasTitle = false
        let hasDescription = false
        if(this.jobs[this.jobNumber].title){
            let title = this.jobs[this.jobNumber].title.toLowerCase()
            hasTitle = this.keyWords.some(word => title.includes(word))
        }
        if(this.jobs[this.jobNumber].description){
            let description = this.jobs[this.jobNumber].description.toLowerCase()
            hasDescription = this.keyWords.some(word => description.includes(word))
        }
        if(hasTitle || hasDescription) await this.insertJob()
        setTimeout(() => {
            this.goToNextJob()
        }, 1000)
    }

  }

}
</script>
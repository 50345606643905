<template>
  <div>
    <!-- Dialog -->
    <v-dialog
        v-model="isTimePickerDialogOpen" 
        overlay-opacity="0.9"
        persistent 
        transition="dialog-bottom-transition"
        width="500"
        style="overflow-x: scroll;">
        <v-toolbar
            class="white--text" 
            height="40">
            <!-- Ok -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="runParentFunction()" 
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-check</v-icon>
            </v-btn>
            </template>
            <span>Save</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <!-- close -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="closeDialog()" 
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Cancel</span>
            </v-tooltip>
        </v-toolbar>
        <v-card
            width="100%"
            elevation="30"
            class="mx-auto px-2 py-1 text-center">
            <v-date-picker
                v-model="deadline"
            ></v-date-picker>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: 'timePickerDialog',
  props: ['value', 'currentDate'],
  data() {
    return {
        isTimePickerDialogOpen: this.value,
        deadline: this.currentDate
    }
  },

  methods: {

    closeDialog(){
        this.isTimePickerDialogOpen= false
        this.$emit('input', this.isTimePickerDialogOpen)
    },

    async runParentFunction() {
      this.$emit('runParentFunction', this.deadline)
      this.closeDialog()
    },

  }

}
</script>
<template>
  <v-dialog 
      v-model="isWelcomeDialogOpen" 
      overlay-opacity="0.9" 
      overlay-color="success darken-1"
      persistent 
      width="500">
      <v-card 
          class="text-center pa-2">
          <div >
                <v-progress-circular
                    :indeterminate="isChecking"
                    size="70"
                    value="100"
                    color="primary">
                    <v-icon v-if="isChecking" size="50" class="primary--text">mdi-lock</v-icon>
                    <span v-else>
                          <v-icon v-if="isUserSignedIn" size="50" class="primary--text">mdi-lock-open-check</v-icon>
                          <v-icon v-else size="50" class="primary--text">mdi-lock-off</v-icon>
                    </span>
                </v-progress-circular>
                <h3 v-if="isChecking">Checking admin information...</h3>
                <h3  v-else>
                      <span v-if="isUserSignedIn">Welcome mr Admin 😊</span>
                      <span v-else>Sorry, you need to log in!</span>
                </h3>
                <!-- Login btn -->
                <v-fade-transition>
                <v-btn
                    v-if="!isChecking && !isUserSignedIn"
                    @click="goToPage('/login')"
                    :key="isUserSignedIn"
                    color="primary"
                    text>
                    Go to Login page
                </v-btn>
                </v-fade-transition>
          </div>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'welcomeDialog',
  props: ['isWelcomeDialogOpen', 'isUserSignedIn', 'isChecking'],
  methods: {
  goToPage(link){
    this.$router.push(link)
  }
},
}
</script>
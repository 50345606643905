<template>
 <div>
     <v-layout wrap>
      <v-flex xs12 sm6 
              v-for="field in fields" 
              v-bind:key="field.text">
              <v-card
                class="mx-auto my-1 text-center"
                max-width="600">
                <!-- Icon -->
                <v-progress-circular
                  :indeterminate="!areNumsLoaded"
                  :value="100"
                  size="200"
                  color="primary">
                <v-icon size="150" class="primary--text">{{field.icon}}</v-icon>
              </v-progress-circular>
                <!-- title -->
                <h3 class="mx-auto mt-2 text-h5 font-weight-bold">
                  {{field.title}}
                </h3>
                <!-- Number -->
                <v-card-text>
                  <span class="text-h2">{{field.num}}</span>
                </v-card-text>
                <!-- btns -->
                <v-card-actions>
                  <v-btn
                    v-if="field.link"
                    @click="goToPage(field.link)"
                    color="primary"
                    text>
                    {{field.btnText}}
                  </v-btn>
                </v-card-actions>
              </v-card>
      </v-flex>
     </v-layout>
 </div>
</template>
<script>
import serverGateway   from  '../modules/serverGateway'
export default {
    name: 'Home',
    data: () => ({
        snackbar: true,
        areNumsLoaded: false,
        fields:[
          {num:0, title:'Number of products', link:'/products', btnText:'Manage products', icon:'mdi-package-variant'},
          {num:0, title:'Number of jobs',     link:'/jobs',     btnText:'Manage jobs',     icon:'mdi-briefcase-outline'},
          {num:0, title:'Number of users',    link:'/users',    btnText:'Manage users',    icon:'mdi-account-multiple-outline'},
          {num:0, title:'Number of requests', link:'/requests', btnText:'Manage requests', icon:'mdi-note'},
          {num:0, title:'Number of likes',    link:'',          btnText:'',                icon:'mdi-thumb-up'},
        ]
    }),

    methods: {

      async loadNumbers(){
          this.fields[0].num = (await serverGateway.getNum('items')).n;
          this.fields[1].num = (await serverGateway.getNum('jobs')).n;
          this.fields[2].num = (await serverGateway.getNum('users')).n;
          this.fields[3].num = (await serverGateway.getNum('requests')).n;
          this.fields[4].num = (await serverGateway.getNum('likes')).n;
          this.areNumsLoaded = true
      },

      goToPage(link){
        this.$router.push(link)
      }

    },

    created(){
      this.loadNumbers()
    }
}
</script>

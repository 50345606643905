<template>
  <div>
    <v-dialog 
      v-model="isDialogOpen"
      overlay-opacity="0.9" 
      overlay-color="info"
      persistent 
      width="800">
      <v-card>
        <v-toolbar
            height="40">
                <v-icon left>mdi-cog-outline</v-icon>
                <strong>Items setting</strong>
                <v-spacer></v-spacer>
                <!-- close -->
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    @click="closeDialog()" 
                    class="primary"
                    small
                    v-bind="attrs"
                    v-on="on">
                    <v-icon small class="white--text">mdi-close</v-icon>
                </v-btn>
                </template>
                <span>Close</span>
                </v-tooltip>
        </v-toolbar>
        <!-- Send revision needed emails -->
        <v-layout class="pa-2" align-center>
                        <span class="mr-6">Send revision needed emails</span>
                        <v-text-field
                                v-model="time"
                                prefix="Interval = "
                                suffix="days"
                                outlined
                                dense
                                rounded
                                hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn
                              @click="checkTimeInterval()"
                              :loading="areResisionEmailsSending"
                              fab
                              color="primary"
                              x-small
                              class="ma-2 white--text text-capitalize">
                              <v-icon dark>mdi-send</v-icon>
                        </v-btn>
        </v-layout>

      </v-card>
    </v-dialog >

    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

    <!-- yesNo Dialog --> 
    <yesNoDialog  v-model="isYesNoDialogOpen" 
                  :key="isYesNoDialogOpen.toString()+'yesNoDialog'" 
                  :YesNoDialogText="'Delete expired jobs?'" 
                  :isLoading="false" 
                  @runParentFunction="deleteExpiredJobs()" />

  </div>
</template>

<script>
import serverGateway from  '../modules/serverGateway'
import yesNoDialog   from  '../components/yesNoDialog.vue';
import snackbar      from  '../components/snackbar.vue';
export default {
  name: 'manageItems',
  components: { yesNoDialog, snackbar },
  props: ['value'],
  data() {
    return {
        isDialogOpen: this.value,
        snacktext: 'Please wait...', 
        time: 31,
        snackbar: false, 
        isYesNoDialogOpen: false,
        areResisionEmailsSending: false,
        error: null
    }
  },

  methods: {

    closeDialog(){
        this.isDialogOpen= false;
        this.$emit('input', this.isDialogOpen);
    },

    async checkTimeInterval(){
        if(this.time > 0){
            let tMiliSeconds = this.time*24*60*60*1000;
            this.sendRevisionReminders(tMiliSeconds);
        }else{
            this.snacktext= 'Please select a valid number 🤕';
            this.snackbar = true;
        }
    },

    async sendRevisionReminders(time){
        this.isYesNoDialogOpen = false
        this.areResisionEmailsSending = true
        try {
                this.snacktext = await serverGateway.remindRevisions(time);
                this.error = null;
                this.snackbar = true;
                this.areResisionEmailsSending = false;
        } catch (error) {
                this.error    = error+ ' 🤕';
                this.snacktext= this.error;
                this.snackbar = true;
                this.areResisionEmailsSending = false;
        }
    },

  },

}
</script>
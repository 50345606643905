<template>
  <div>
    <!-- Dialog -->
    <v-dialog
        v-model="isDialogOpen" 
        overlay-opacity="0.9"
        persistent 
        transition="dialog-bottom-transition"
        width="1400"
        style="overflow-x: scroll;">
        <v-toolbar 
            height="40">
            <v-spacer></v-spacer>
            <!-- close -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="closeDialog()"
                :disabled="isChangingCoins"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Close</span>
            </v-tooltip>
        </v-toolbar>
        <v-card
            width="100%"
            elevation="30"
            class="mx-auto pa-2">
            <!-- Coins field -->
            <v-layout wrap class="px-6 py-3 indigo">
                <v-flex xs12 sm6 align-self-center class="px-12">
                    <v-text-field
                        v-model.number="itemsCoins"
                        label="Add/Subtract coins of items"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="setForm('+', 'items', itemsCoins); isYesNoDialogOpen= true"
                        prepend-icon="mdi-minus"
                        @click:prepend="setForm('-', 'items', itemsCoins); isYesNoDialogOpen= true"
                        rounded
                        outlined
                        dense
                        hide-details
                        dark
                    ></v-text-field>
                </v-flex>               
                <v-flex xs12 sm6 align-self-center class="px-12">
                    <v-text-field
                        v-model.number="jobsCoins"
                        label="Add/Subtract coins of jobs"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="setForm('+', 'jobs', jobsCoins) ; isYesNoDialogOpen= true"
                        prepend-icon="mdi-minus"
                        @click:prepend="setForm('-', 'jobs', jobsCoins); isYesNoDialogOpen= true"
                        rounded
                        outlined
                        dense
                        hide-details
                        dark
                    ></v-text-field>
                </v-flex>
            </v-layout>
            <!-- Content -->
            <v-layout wrap class="px-6 py-3">
                <!-- User info -->
                <v-flex  xs12 md6 class="text-center pa-10" align-self-center>
                    <!-- First name -->
                    <v-layout row class="mx-1 my-2">
                        <span><v-icon>mdi-account-circle-outline</v-icon> First name:</span>
                        <v-spacer></v-spacer> {{selectedItem.first_name}}
                    </v-layout>
                    <!-- Last name -->
                    <v-layout row class="mx-1 my-2">
                        <span><v-icon>mdi-human-male-female-child</v-icon> Last name:</span>
                        <v-spacer></v-spacer> {{selectedItem.last_name}}
                    </v-layout>
                    <!-- Country -->
                    <v-layout row class="mx-1 my-2">
                        <span><v-icon>mdi-island</v-icon> Country:</span>
                        <v-spacer></v-spacer> {{selectedItem.country}}
                    </v-layout>
                    <!-- Items coins -->
                    <v-layout row class="mx-1 my-2">
                        <span><v-icon>mdi-package-variant</v-icon> Items coins:</span>
                        <v-spacer></v-spacer> {{selectedItem.maxItems}}
                    </v-layout>
                    <!-- Jobs coins -->
                    <v-layout row class="mx-1 my-2">
                        <span><v-icon>mdi-briefcase-variant-outline</v-icon> Jobs coins:</span>
                        <v-spacer></v-spacer> {{selectedItem.maxJobs}}
                    </v-layout>
                    <!-- Email -->
                    <v-layout row class="mx-1 my-2">
                        <span><v-icon>mdi-email</v-icon> Email:</span>
                        <v-spacer></v-spacer> {{selectedItem.email}}
                    </v-layout>
                    <!-- created_at -->
                    <v-layout row  class="mx-1 my-2">
                        <span><v-icon>mdi-calendar</v-icon> Joined at: </span>
                        <v-spacer></v-spacer> {{(selectedItem.created_at).split(' ')[0] }}
                    </v-layout>
                </v-flex>
                <!-- Email sth -->
                <v-flex  xs12 md6 class="text-center pa-10" align-self-center>
                        Email
                </v-flex>
            </v-layout>
            <!-- User requests -->
            <userRequests :userEmail="selectedItem.email"/>
        </v-card>
    </v-dialog>

    <!-- yesNo Dialog --> 
    <yesNoDialog  v-model="isYesNoDialogOpen" 
                  :key="isYesNoDialogOpen.toString()+'yesNoDialog'" 
                  :YesNoDialogText="'Change the number of coins?'" 
                  :isLoading="false" 
                  @runParentFunction="changeCoins()" />

    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext" :isError="error ? true : false"/>

  </div>
</template>

<script>
import snackbar          from  './snackbar.vue';
import yesNoDialog       from  './yesNoDialog.vue';
import userRequests       from  './userRequests.vue';
import serverGateway     from  '../modules/serverGateway';
export default {
  name: 'showUsersDialog',
  components: { snackbar, yesNoDialog, userRequests },
  props: ['value', 'selectedItem', 'usersList'],
  data() {
    return {
        isDialogOpen: this.value,
        isChangingCoins: false,
        isYesNoDialogOpen: false,
        itemsCoins: 0,
        jobsCoins: 0,
        error: null,
        snacktext: 'Please wait...', 
        snackbar: false,
        form: {
            sign: '+',
            type: 'items', 
            coins: 0,
            email: this.selectedItem.email
        }
    }
  },

  methods: {

    closeDialog(){
        this.isDialogOpen= false;
        this.$emit('input', this.isDialogOpen);
        this.$router.replace('/users');
    },

    async setForm(sign, type, coins){
        this.form.sign = sign;
        this.form.type = type;
        this.form.coins = coins;
    },

    async changeCoins(){
        this.isYesNoDialogOpen = false;
        if(this.form.coins <= 0){
            this.snacktext = 'Please select a positive value';
            this.snackbar  = true;
        }else{        
            this.isChangingCoins = true;
            try {
                let x = await serverGateway.changeCoins(this.form);
                console.log(x)
                this.error = null;
                if(this.form.sign == '-') this.snacktext = `Admin decreased ${this.form.type} coins successfully`;
                if(this.form.sign == '+') this.snacktext = `Admin increased ${this.form.type} coins successfully`;
                this.snackbar  = true;
                this.refreshUser();
            } catch (error) {
                this.error    = error+ ' 🤕';
                this.snacktext = this.error;
                this.snackbar  = true;
            }
            this.isChangingCoins = false;
        }

    },

    async refreshUser(){
        try {
            let user = await serverGateway.getUser(this.selectedItem.email);
            this.selectedItem.maxItems = user.maxItems;
            this.selectedItem.maxJobs  = user.maxJobs;
            let index = this.usersList.findIndex(user => user.email == this.selectedItem.email );
            if(index >= 0){
                this.usersList[index].maxItems = user.maxItems;
                this.usersList[index].maxJobs = user.maxJobs;
            }
        } catch (error) {
            this.error    = error+ ' 🤕';
            this.snacktext= this.error;
            this.snackbar = true;
        }
    },

  },

}
</script>
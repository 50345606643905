export default {

  revisionFields: [
      { 
        active: false,
        header: 'Title',
        text: '',
        textOptions: [
          'Please use polite words for title',
          'Title is not related to the selected category'
        ]
      },
      { 
        active: false,
        header: 'Category (Industry - Field)',
        text: '',
        textOptions: [
          'Selected category is not related to your product'
        ]
      },
      { 
        active: false,
        header: 'Link',
        text: '',
        textOptions: [
          'Link is not active',
          'Link is not related to your product'
        ]
      },
      { 
        active: false,
        header: 'Link of 3D model',
        text: '',
        textOptions: [
          'Link of 3D model is not active',
          'Link of 3D model is not related to your product'
        ]
      },
      { 
        active: false,
        header: 'Price',
        text: '',
        textOptions: [
          'Price is too big!',
          'Price is too small!'
        ]
      },
      { 
        active: false,
        header: 'Price unit',
        text: '',
        textOptions: [
        ]
      },
      { 
        active: false,
        header: 'Email or Phone Number',
        text: '',
        textOptions: [
          'You are not allowed to use gmail or yahoo',
          'Phone number is not active'
        ]
      },
      { 
        active: false,
        header: 'Description',
        text: '',
        textOptions: [
          'You are not allowed to use impolite words in description',
          'Its better to write description in English'
        ]
      }
  ],

  // Academic crawlers
  academicCrawlers: [
      { 
          title: 'Academic Positions website', 
          id: 'acpo',
          urls: [
            'https://academicpositions.com/find-jobs?search=energy', 
            'https://academicpositions.com/find-jobs?search=energy&page=2', 
            'https://academicpositions.com/find-jobs?search=energy&page=3',
            'https://academicpositions.com/find-jobs?search=energy&page=4',
            'https://academicpositions.com/find-jobs?search=energy&page=5'
          ]
      },
      { 
          title: 'NTNU',      
          id: 'ntnu',
          urls: [
            'https://www.ntnu.edu/vacancies?p_p_id=vacanciesportlet_WAR_vacanciesportlet&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_resource_id=feed&p_p_cacheability=cacheLevelPage'
          ]
      },
      { 
          title: 'Univeristy of Stavanger', 
          id: 'uis',
          urls: [
            'https://www.uis.no/en/about-uis/vacant-positions-at-uis'
          ]
      },
      { 
          title: 'Montanuniversität Leoben', 
          id: 'leoben',
          urls: [
            'https://www.unileoben.ac.at/en/jobs/'
          ]
      },
      { 
          title: 'DTU', 
          id: 'dtu',
          urls: [
            'https://www.dtu.dk/english/about/job-and-career/vacant-positions?fr=1&jobpage=4fabb82b-ffd3-4a95-9a1c-a24a4f4ae569&mr=100&type=Videnskabeligt&inst=55482799#jobFilter',
            'https://www.dtu.dk/english/about/job-and-career/vacant-positions?fr=1&jobpage=4fabb82b-ffd3-4a95-9a1c-a24a4f4ae569&mr=100&type=Videnskabeligt&inst=55004792#jobFilter',
            'https://www.dtu.dk/english/about/job-and-career/vacant-positions?fr=1&jobpage=4fabb82b-ffd3-4a95-9a1c-a24a4f4ae569&mr=100&type=Videnskabeligt&inst=55004790#jobFilter',
            'https://www.dtu.dk/english/about/job-and-career/vacant-positions?fr=1&jobpage=4fabb82b-ffd3-4a95-9a1c-a24a4f4ae569&mr=100&type=Videnskabeligt&inst=56000016#jobFilter',
            'https://www.dtu.dk/english/about/job-and-career/vacant-positions?fr=1&jobpage=4fabb82b-ffd3-4a95-9a1c-a24a4f4ae569&mr=100&type=Videnskabeligt&inst=55004756#jobFilter'
          ]
      },
      { 
          title: 'University of Twente', 
          id: 'twente',
          urls: [
            'https://utwentecareers.nl/en/vacancies/?type=WP&faculty=ITC,TNW&page=1'
          ]
      },
      { 
          title: 'Juelich Research Center', 
          id: 'juelich',
          urls: [
            'https://www.fz-juelich.de/++api++/en/careers/jobs/@job-offer-search?path_prefix=/en/careers/jobs/&q=&rows=200&sort=created desc&start=0&subject=chemie&subject=ingenieurswissenschaften-technik'
          ]
      },
  ],

  // Academic crawlers
  industrialCrawlers:[
    { 
        title: 'Halliburton', 
        id: 'halib',
        urls:[
          'https://jobs.halliburton.com/go/Field-Operations/8412100/', 
          'https://jobs.halliburton.com/go/Professional/8410100/'
        ]
    },
    { 
        title: 'Wintershall',               
        id: 'wnsh',
        urls:[
          'https://careers.wintershalldea.com/en_US/careers/SearchJobs/?jobRecordsPerPage=10&jobOffset=0', 
          'https://careers.wintershalldea.com/en_US/careers/SearchJobs/?jobRecordsPerPage=10&jobOffset=10',
          'https://careers.wintershalldea.com/en_US/careers/SearchJobs/?jobRecordsPerPage=10&jobOffset=20'
        ]
    },
    { 
        title: 'Eni', 
        id: 'eni',
        urls:[
          'https://www.eni.com/en-IT/careers.html'
        ]
    },
    { 
        title: 'Schlumberger', 
        id: 'schl',
        urls:[
          'https://careers.slb.com/job-listing'
        ]
    },
    { 
        title: 'First Solar', 
        id: 'fiso',
        urls:[
          'https://fa-esbv-saasfaprod1.fa.ocs.oraclecloud.com/hcmRestApi/resources/latest/recruitingCEJobRequisitions?onlyData=true&expand=requisitionList.secondaryLocations,flexFieldsFacet.values&finder=findReqs;siteNumber=CX_1,facetsList=LOCATIONS;WORK_LOCATIONS;TITLES;CATEGORIES;ORGANIZATIONS;POSTING_DATES;FLEX_FIELDS,limit=25,sortBy=POSTING_DATES_DESC',
          'https://fa-esbv-saasfaprod1.fa.ocs.oraclecloud.com/hcmRestApi/resources/latest/recruitingCEJobRequisitions?onlyData=true&expand=requisitionList.secondaryLocations,flexFieldsFacet.values&finder=findReqs;siteNumber=CX_2001,facetsList=LOCATIONS;WORK_LOCATIONS;TITLES;CATEGORIES;ORGANIZATIONS;POSTING_DATES;FLEX_FIELDS,limit=25,sortBy=POSTING_DATES_DESC',
          'https://fa-esbv-saasfaprod1.fa.ocs.oraclecloud.com/hcmRestApi/resources/latest/recruitingCEJobRequisitions?onlyData=true&expand=requisitionList.secondaryLocations,flexFieldsFacet.values&finder=findReqs;siteNumber=CX_4,facetsList=LOCATIONS;WORK_LOCATIONS;TITLES;CATEGORIES;ORGANIZATIONS;POSTING_DATES;FLEX_FIELDS,limit=25,sortBy=POSTING_DATES_DESC'
        ]
    },
    { 
        title: 'ExxonMobil', 
        id: 'exonm',
        urls:[
          'https://jobs.exxonmobil.com/go/Engineering/3845600/'
        ]
    },
  ],

}
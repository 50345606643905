<template>
  <div>
              
              <!-- Error -->
              <h3 v-if="error"  class="error--text text-center my-1">{{error}}</h3>

              <v-toolbar class="my-2" elevation="5" dense>
                            <h3>
                              <v-icon>{{icon}}</v-icon>
                              {{title}}
                            </h3>
                            <v-spacer></v-spacer>

                            <!-- Crawl btn -->
                            <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="findjobs"
                                :loading="isSearching"
                                class="primary text-capitalize mr-1"
                                small
                                rounded
                                v-bind="attrs"
                                v-on="on">
                                Crawl
                                <v-icon  small class="ml-1 white--text">mdi-cloud-search-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Add new jobs</span>
                            </v-tooltip>

                            <!-- edit urls btn -->
                            <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                @click="isUrlEditorOpen = true"
                                class="primary"
                                small
                                v-bind="attrs"
                                v-on="on">
                                <v-icon  small class="white--text">mdi-update</v-icon>
                                </v-btn>
                            </template>
                            <span>Update URLs</span>
                            </v-tooltip>

              </v-toolbar>

              <!-- snackbar -->
              <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

              <!-- showJobsDialogRegister -->
              <showJobsDialogRegister     v-if="jobs.length>0"
                                          :key="isDialogOpen" 
                                          v-model="isDialogOpen" 
                                          :jobs="jobs"
                                          @chengeParentIcon="icon='mdi-check'"/>

              <!-- information Dialog -->
              <informationDialog          v-model="isInformationDialogOpen" 
                                          :key="isInformationDialogOpen.toString()+'informationDialog'" 
                                          :header="informationDialogHeader"
                                          :message="informationDialogMessage"
                                          :isSearching="isSearching"
                                          @runParentFunction="openDialogRegister()"/>

              <!-- urlsEditor -->
              <urlsEditor                 v-model="isUrlEditorOpen"
                                          :key="isUrlEditorOpen.toString()+'urlsEditor'"
                                          :title="title"
                                          :urlsList="urlsList" />

  </div>
</template>

<script>
import serverGateway            from  '../../modules/serverGateway'
import snackbar                 from  '../snackbar.vue'
import informationDialog        from  './informationDialog.vue'
import showJobsDialogRegister   from  './showJobsDialogRegister.vue'
import urlsEditor               from  './urlsEditor.vue'
export default {
  name: 'crawl',
  components: { snackbar, informationDialog, showJobsDialogRegister, urlsEditor },
  props: ['title', 'id', 'urlsList'],
  data () {
    return {
              snacktext: 'Please wait...', 
              snackbar: false,
              jobs:[],
              isSearching: false,
              isDialogOpen: false,
              isUrlEditorOpen: false,
              isInformationDialogOpen: false,
              informationDialogMessage: "Please wait...",
              informationDialogHeader: `Crawling ${this.title}`,
              error: null,
              icon: 'mdi-help-circle-outline'
    }
  },

methods:{

                async findjobs(){
                                this.isSearching = true
                                this.isInformationDialogOpen = true
                                this.makeZero()
                                try {
                                            this.jobs = await serverGateway.getNewJobs(this.id, this.urlsList)
                                            this.error = null
                                            if(this.jobs.length > 0){
                                                            this.informationDialogMessage = 'Found ' + this.jobs.length + ' jobs'
                                                            this.informationDialogHeader = "Crawling completed"
                                                            this.isSearching = false
                                            }else{
                                                            this.isInformationDialogOpen = false;
                                                            this.isSearching = false;
                                                            this.snacktext = 'Sorry, no job is found';
                                                            this.snackbar = true;
                                            }
                                } catch (error) {
                                            this.isInformationDialogOpen = false
                                            this.error    = error+ ' 🤕'
                                            this.snacktext= this.error
                                            this.snackbar = true
                                            this.isSearching = false
                                }
                },

                openDialogRegister(){
                                this.isInformationDialogOpen = false
                                this.isDialogOpen = true
                },

                makeZero(){
                            this.jobs= []
                            this.informationDialogMessage= "Please wait..."
                            this.informationDialogHeader= `Crawling ${this.title}`
                }

},

}
</script>

<template>
  <v-dialog 
      v-model="isShowHTMLOpen" 
      overlay-opacity="0.9" 
      transition="dialog-bottom-transition"
      width="700">
      <v-card>
          <v-toolbar 
              height="40">
              <strong>See HTML of description</strong>
              <v-spacer></v-spacer>
              <!-- Close btn -->
              <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  @click="close()"
                  class="primary"
                  small
                  v-bind="attrs"
                  v-on="on">
                  <v-icon small class="white--text">mdi-check</v-icon>
              </v-btn>
              </template>
              <span>Ok</span>
              </v-tooltip>
        </v-toolbar>
        <!-- editor -->
        <p class="mx-1 pa-3 my-2 justifyText"  v-html="html"></p>

      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'urlsEditor',
  props: ['value', 'description'],
  data() {
    return {
      html: this.description,
      isShowHTMLOpen: this.value,
    }
  },
  methods: {

    close(){
      this.isShowHTMLOpen = false;
      this.$emit('input', this.isShowHTMLOpen);
    }

  },
  
  watch:{
    isShowHTMLOpen (){
      if(!this.isShowHTMLOpen)  this.$emit('input', this.isShowHTMLOpen);
    }
  },

  created(){
      this.html= this.html.replace(/(\r\n|\n|\r)/gm, "<br/>")
  }

}
</script>
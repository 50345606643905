<template>
  <v-app>
    <v-main>
        <!-- Navbar -->
        <Navbar :nameofCurrentRoute="nameofCurrentRoute"/>
        <!-- Routers -->
        <v-scale-transition hide-on-leave>
        <router-view></router-view>
        </v-scale-transition>
        <!-- welcomeDialog -->
        <welcomeDialog  :isWelcomeDialogOpen="isWelcomeDialogOpen" 
                        :isUserSignedIn="isUserSignedIn" 
                        :isChecking="isChecking"/>
    </v-main>
  </v-app>
</template>

<script>
import serverGateway from './modules/serverGateway'
import Navbar        from './components/Navbar.vue'
import welcomeDialog from './components/welcomeDialog.vue'
export default {
  name: 'App',
  components: {  Navbar, welcomeDialog  },
  data () {
    return {
      nameofCurrentRoute: null,
      isUserSignedIn: false,
      isWelcomeDialogOpen: true,
      isChecking: true
    }
  },
  methods:{

    async welcomeUser(){
      let response = await serverGateway.ensureInitialized()
      if(response){
        this.isUserSignedIn= true
        setTimeout(()=>{ this.isWelcomeDialogOpen = false }, 2000)
        this.isChecking = false
      }else{
        this.isUserSignedIn= false 
        this.isWelcomeDialogOpen = false
        this.isChecking = false
      }
    }

  },
  created(){
      this.welcomeUser()
      this.nameofCurrentRoute = this.$route.name
      serverGateway.setTheme()
  },
  watch:{
    $route (to){
      this.nameofCurrentRoute = to.name
    }
  }
}
</script>

<style>
  .v-btn--active::before, .v-btn:focus::before {
    opacity: 0 !important;
  }
  .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .normalBtnText{
    text-transform: none;
  }
  .justifyText{
    text-align: justify;
    text-justify: inter-word;
  }
</style>
<template>
  <div class="mb-14 pb-14 mx-md-8 mx-4">

      <!-- Error -->
      <h3 v-if="error"  class="error--text text-center my-2">{{error}}</h3>

      <!-- Table -->
      <v-card>
          <v-card-title>
            Requests of users
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="requestsList"
            :search="search"
            :loading="isListLoading"
            loading-text="Loading... Please wait"
            @click:row="openUser"
          ></v-data-table>
      </v-card>

      <!-- snackbar -->
      <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import serverGateway  from  '../modules/serverGateway';
import snackbar       from  '../components/snackbar.vue';
export default {
  name: 'Requests',
  components: { snackbar },
  data () {
    return {
      snacktext: 'Please wait...', 
      snackbar: false,
      isDialogOpen: false,
      requestsList: [],
      isListLoading: true,
      isSearchingModal: false,
      error: null,
      search: '',
        headers: [
          { text: 'Creator',      value: 'creator', align: 'start' },
          { text: 'Type',         value: 'type' },
          { text: 'Text',         value: 'text' },
          { text: 'Created at',   value: 'created_at' }
        ],
    }
  },

  methods:{


    async getRequestsList(){ 
      try {
            this.requestsList = await serverGateway.getRequests()
            this.isListLoading = false
          } catch (error) {
            this.error    = error+ ' 🤕';
            this.snacktext= this.error;
            this.snackbar = true;
            this.isListLoading = false;
          }
    },

    async openUser(row){ 
      window.open(window.location.origin + '/users/' + row.creator, "_blank");
    },

  },

  created(){
    this.getRequestsList();
  }

}
</script>

<template>
  <div class="mb-14 pb-14 mx-md-8 mx-4">

      <!-- Error -->
      <h3 v-if="error"  class="error--text text-center my-2">{{error}}</h3>

      <!-- Table -->
      <v-card>
          <v-card-title>
            Users of energyhub
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="usersList"
            :search="search"
            :loading="isListLoading"
            loading-text="Loading... Please wait"
            @click:row="openUser"
          ></v-data-table>
      </v-card>

      <!-- show Jobs Dialog -->
      <router-view  v-if="isDialogOpen" 
                    v-model="isDialogOpen" 
                    :selectedItem="selectedItem"
                    :usersList="usersList">
      </router-view>

      <!-- Loading dialog -->
      <loadingDialog        :isLoadingDialogOpen="isSearchingModal" :header="'Finding desired user'" />

      <!-- snackbar -->
      <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import serverGateway  from  '../modules/serverGateway';
import snackbar       from  '../components/snackbar.vue';
import loadingDialog  from  '../components/loadingDialog.vue';
export default {
  name: 'Users',
  components: { snackbar, loadingDialog },
  data () {
    return {
      snacktext: 'Please wait...', 
      snackbar: false,
      isDialogOpen: false,
      usersList: [],
      isListLoading: true,
      isSearchingModal: false,
      error: null,
      search: '',
        headers: [
          { text: 'First name',    value: 'first_name', align: 'start' },
          { text: 'Last name',     value: 'last_name' },
          { text: 'Country',       value: 'country' },
          { text: 'Email address', value: 'email' },
          { text: 'Job coins',     value: 'maxJobs' },
          { text: 'Item coins',    value: 'maxItems' },
          { text: 'Created at',    value: 'created_at' }
        ],
    }
  },

  methods:{


    async getUsersList(){ 
      try {
            this.usersList = await serverGateway.getUsersList()
            this.isListLoading = false
          } catch (error) {
            this.error    = error+ ' 🤕'
            this.snacktext= this.error
            this.snackbar = true
            this.isListLoading = false
          }
    },

    async openUser(row){ 
      this.selectedItem = row;
      this.isDialogOpen= true;
      this.$router.push('users/'+this.selectedItem.email);
    },

    async loadUser(){
        try {
            this.isSearchingModal = true
            this.selectedItem = await serverGateway.getUser(this.$route.params.modal)
            if(this.selectedItem){
                this.isDialogOpen = true;
                this.isSearchingModal = false;
            }
            else{
                this.isSearchingModal = false;
                this.$router.push('/users');
                this.snacktext= 'Wrong address';
                this.snackbar = true;
            }
        } catch (error) {
            this.error    = error+ ' 🤕';
            this.snacktext= this.error;
            this.snackbar = true;
        }
    },
    

    async checkModal(){
      this.isDialogOpen = false
      if (this.$route.params.modal) this.loadUser();
    },

  },

  created(){
    this.getUsersList();
    this.checkModal();
  }

}
</script>

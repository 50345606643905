<template>
<div>
    <div v-if="areRequestsLoaded" class="px-3 py-2">
        <strong v-if="requests.length > 0">List of user requests</strong>
        <strong v-else>This user has no request</strong>
        <v-scroll-y-transition
                    group 
                    class="layout wrap">
                    <v-toolbar dense flat  v-for="(request, index) in requests"  :key="request._id">
                        {{request.text}}
                        <v-spacer></v-spacer> 
                        <v-btn
                            @click="selectedIndex=index; isYesNoDialogOpen = true"
                            :disabled="isRequestDeleting"
                            icon
                            class="primary"
                            small>
                            <v-icon small class="white--text">mdi-delete</v-icon>
                        </v-btn>
                    </v-toolbar>
        </v-scroll-y-transition>
    </div>
    <!-- yesNo Dialog --> 
    <yesNoDialog  v-model="isYesNoDialogOpen" 
                  :key="isYesNoDialogOpen.toString()+'yesNoDialog'" 
                  :YesNoDialogText="'Is request fullfilled?'" 
                  :isLoading="false" 
                  @runParentFunction="removeRequest()" />

    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext" :isError="error ? true : false"/>
    
</div>
</template>

<script>
import serverGateway     from  '../modules/serverGateway';
import snackbar          from  '../components/snackbar.vue';
import yesNoDialog       from  '../components/yesNoDialog.vue';
export default {
    name: 'userRequests',
    components: { snackbar, yesNoDialog },
    props: ['userEmail'],
    data() {
    return {
        requests: [],
        snacktext: 'Please wait...',
        selectedIndex: 0,
        snackbar: false,
        isYesNoDialogOpen: false,
        areRequestsLoaded: false,
        isRequestDeleting: false,
        error: null,
    }
    },


    methods:{

        async getRequests() {
            try {
                this.requests = await serverGateway.getUserRequests(this.userEmail);
                this.error    = null;
            } catch (error) {
                this.error    = error+ ' 🤕'
                this.snacktext= this.error;
                this.snackbar = true;
            }
            this.areRequestsLoaded = true;
        },

        async removeRequest() {
            this.isRequestDeleting = true;
            try {
                await serverGateway.deleteRequest(this.requests[this.selectedIndex]._id);
                this.requests.splice(this.selectedIndex, 1);
                this.error    = null;
                this.snacktext= 'Request removed';
                this.snackbar = true;
                this.isRequestDeleting = false;
            } catch (error) {
                this.error    = error+ ' 🤕'
                this.snacktext= this.error;
                this.snackbar = true;
                this.isRequestDeleting = false;
            }
            this.isYesNoDialogOpen = false;
        },

    },

    created(){
        this.getRequests();
    }

}
</script>
<template>
  <div>
    <v-dialog 
        v-model="isDialogOpen" 
        v-if="!isChecking"
        overlay-opacity="0.9" 
        persistent 
        width="600">
        <v-toolbar class="primary white--text">
            <span class="text-body-1">
              Please login
            </span>
        </v-toolbar>
        <v-card
            class="pa-2"
            light>
            <!-- Erroe -->
            <h3 v-if="error"  class="error--text text-center my-1">Error: {{error}}</h3>
            <!-- Username -->
            <h4 class="ml-14">Email:</h4>
            <v-text-field 
                v-model="username"
                :rules="[rules.required]"
                class="mx-6" 
                outlined 
                dense 
                clearable 
                tabindex="1" 
                v-on:keyup.enter="login()" 
                prepend-icon="mdi-account"
            ></v-text-field>
            <!-- Password -->
            <h4 class="ml-14">Password:</h4>
            <v-text-field 
                v-model="password"
                :rules="[rules.required]"
                class="mx-6" 
                outlined 
                dense 
                clearable 
                tabindex="2" 
                v-on:keyup.enter="login()"
                prepend-icon="mdi-lock"
                :append-icon="appendicon ? 'mdi-eye' : 'mdi-eye-off'"
                :type="appendicon ? 'text' : 'password'"
                @click:append="appendicon = !appendicon"
            ></v-text-field>
            <!-- BTNS -->
            <v-btn  
                @click="login" 
                small
                color="primary" 
                class="ml-md-7" 
                :loading="isUserLogging" 
                :disabled="username == null || password == null">LOGIN
            </v-btn>
        </v-card>
    </v-dialog>
    
    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import serverGateway from '../modules/serverGateway'
import snackbar      from '../components/snackbar.vue'
export default {
  name: 'SignInDialog',
  components: { snackbar },
  data() {
    return {
        isDialogOpen: true,
        username: '',
        password: '',
        rules: {  required: value => !!value || 'Required'   },
        isUserLogging: false,
        isChecking: true,
        error: null,
        appendicon: false,
        snacktext: 'Welcome to your app!', 
        snackbar: false
    }
  },
  methods: {

    async login() {
      this.isUserLogging = true
      try {
          await serverGateway.login(this.username, this.password)
          this.error= null
          let user = await serverGateway.findMe()
          this.snacktext = `Successfully loged in as ${user.first_name} ${user.last_name}!`
          this.snackbar  = true
          setTimeout(()=>{ this.$router.push('/') }, 1000)
      } catch (error) {
          this.error     = error
          this.snacktext = error
          this.snackbar  = true
      }
      this.isUserLogging = false
    }

  },

  async created () {
    this.isChecking = true
    let response = await serverGateway.ensureInitialized()
    if(response){
        this.isUserSignedIn= true
        this.isWelcomeDialogOpen = false
        this.$router.back()
        this.isChecking = false
      }else{
        this.isUserSignedIn= false 
        this.isWelcomeDialogOpen = false
        this.isChecking = false
    }
  }

}
</script>
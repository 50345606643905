<template>
  <v-dialog 
      v-model="isInformationDialogOpen" 
      overlay-opacity="0.9" 
      transition="dialog-bottom-transition"
      persistent 
      width="600">
      <v-card
          class="text-center">
          <v-toolbar 
            height="40">
            <v-spacer></v-spacer>
            <!-- Header -->
            <v-slide-x-transition hide-on-leave>
            <h2 class="my-4" :key="header">{{header}}</h2>
            </v-slide-x-transition>
            <v-spacer></v-spacer>
            <!-- Close btn -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="cancel()"
                :disabled="isSearching"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Cancel</span>
            </v-tooltip>
        </v-toolbar>

        <!-- progress -->
        <v-progress-circular
            v-if="isSearching"
            indeterminate
            :size="60"
            :width="7"
            color="blue"
            class="my-4"
        ></v-progress-circular>
        <v-progress-circular
            v-else 
            color="blue" 
            class="my-4"
            :size="60"
            :width="7"
            value="100">100%
        </v-progress-circular>
        <!-- message -->
        <v-slide-x-transition hide-on-leave>
        <h3 class="my-4" :key="message">
          <span>{{message}} <v-icon v-if="!isSearching">mdi-check</v-icon></span>
        </h3>
        </v-slide-x-transition>
        <!-- Add btn -->
        <v-btn
            @click="runParentFunction()"
            :disabled="isSearching"
            color="primary"
            class="ma-4 text-capitalize"
            small>Start adding jobs
            <v-icon small right class="white--text">mdi-briefcase-plus-outline</v-icon>
        </v-btn>

      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'informationDialog',
  props: ['value', 'header', 'message', 'isSearching'],
  data() {
    return {
      isInformationDialogOpen: this.value,
    }
  },
  methods: {
    runParentFunction() {
      this.$emit('runParentFunction')
    },
    cancel(){
      this.isInformationDialogOpen= false
      this.$emit('input', this.isInformationDialogOpen)
    }
  }

}
</script>
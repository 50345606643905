<template>
  <!-- Snackbar -->
  <v-scale-transition>
  <v-snackbar 
      v-model="snackbar"
      :timeout="isError ? 20000 : 5000"
      rounded="pill"
      :key="snacktext"
      :top="isError"
      class="mb-12"
      :color="isError ? 'error' : 'primary'">
          {{snacktext}}
          <template v-slot:action="{ attrs }">
          <v-btn
            plain
            v-bind="attrs"
            @click="snackbar = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
  </v-snackbar>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'snackbar',
  props:['value', 'snacktext', 'isError'],
  data() {
    return {
      snackbar: this.value
    }
  },
  watch: {
    snackbar: function () {
      this.$emit('input', this.snackbar)
    }
  }
}
</script>
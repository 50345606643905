<template>
  <v-layout wrap>
        <v-flex xs12 sm6  
                v-for="item in items" 
                v-bind:key="item.id">
                <crawl :title="item.title"  :id="item.id" :urlsList="item.urls"/>
        </v-flex>
  </v-layout>
</template>

<script>
import globalFunctions  from '../../modules/globalFunctions'
import crawl     from './crawl.vue'
export default {
  components: { crawl },
  name: 'CrawlerIndustrial',
  data() {
    return {
      items: globalFunctions.industrialCrawlers
    }
  }
}
</script>

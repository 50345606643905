import axios from "axios"

var main = {

  baseUrl: "https://api.energyhub.info/",
  crawlerUrl: "https://crawler.energyhub.info/",
  // baseUrl: "http://localhost:5500/",
  // crawlerUrl: "http://localhost:5502/",
  accessToken: null,

  async execute (method, resource, data) {
    try {
        return (await axios({
          method,
          url: this.baseUrl+resource,
          data,
          headers: { 'x-access-token': this.accessToken },
        })).data
    } catch (error) {
        if (error.response && error.response.data) {
            throw error.response.data
        }
        throw error
    }
  },
  
  // Login
  async login(username, password) {
    return this.execute('post', 'login', { username, password } )
  },
  async requestNewPassword(id) {
    return  this.execute('get', 'request-new-password/'+id)
  },
  async changePasword(token, password) {
    return this.execute('post', 'change-password', { token, password } )
  },

  // User routes
  async insertNewUser(userItem) {
    return  this.execute('post', 'user', userItem )
  },
  async findMe() {
    return  this.execute('get', 'user' )
  },
  async getUsersList() {
    return  this.execute('get', 'users-list' )
  },
  async getUser(email) {
    return  this.execute('get', 'user-single/' + email )
  },
  async changeCoins(form) {
    return  this.execute('post', 'change-coins', form);
  },

  // items routes
  async insertItem(item, file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('item', JSON.stringify(item))
    // return await axios.post(this.baseUrl+'item', formData)
    return (await axios({
      method: 'post',
      url: this.baseUrl+'item',
      data: formData,
      headers: { 'x-access-token': this.accessToken },
    })).data
  },
  async getItems(queries) {
    return  this.execute('post', 'items', queries)
  },
  async getItem(id) {
    return  this.execute('get', 'item/'+id)
  },
  async getUserItems() {
    return  this.execute('get', 'useritems')
  },
  async deleteItem(item_id) {
    return  this.execute('delete', 'item/'+item_id)
  },
  async remindRevisions(time) {
    return  this.execute('get', 'remind-revisions/'+time)
  },

  // Image routes
  async getImage(item_id) {
    return  this.execute('get', 'image/'+item_id)
  },

  // Email routes
  async sendEmail(contactForm) {
    return  this.execute('post', 'send-email', contactForm )
  },
  async sendRegisteringEmail(contactForm) {
    return  this.execute('post', 'send-registering-email', contactForm )
  },
  async sendInformationEmail(emailForm) {
    return  this.execute('post', 'send-information-email', emailForm )
  },

  // Job routes
  async getJobs(queries) {
    return  this.execute('post', 'jobs', queries)
  },
  async getJobsAdmin(queries) {
    return  this.execute('post', 'jobs-admin', queries)
  },
  async getJobAdmin(id) {
    return  this.execute('get', 'job-admin/'+id)
  },
  async insertJob(jobItem) {
    return  this.execute('post', 'job', jobItem )
  },
  async updateJob(JobItem) {
    return  this.execute('put', 'job', JobItem )
  },
  async deleteJob(job_id) {
    return  this.execute('delete', 'job/'+job_id)
  },
  async publishJob(JobItem) {
    return  this.execute('post', 'job-publish', JobItem)
  },
  async makeJobPublic(job_id) {
    return  this.execute('get', 'job-make-public/' + job_id)
  },
  async makeJobHidden(job_id) {
    return  this.execute('get', 'job-make-hidden/' + job_id )
  },
  async deleteExpiredJobs(time) {
    return  this.execute('get', 'jobs-delete-expired/' + time)
  },

  // like routes
  async getUserLikes() {
    return  this.execute('get', 'userlikes')
  },
  async getLike(item_id) {
    return  this.execute('get', 'like/'+item_id)
  },
  async insertLike(item_id) {
    return  this.execute('post', 'like/'+item_id)
  },
  async deleteLike(item_id) {
    return  this.execute('delete', 'like/'+item_id)
  },

  // Request routes
  async getRequests() {
    return  this.execute('get', 'all-requests')
  },
  async getUserRequests(email) {
    return  this.execute('get', 'requests/'+email)
  },
  async deleteRequest(id) {
    return  this.execute('delete', 'request/'+id)
  },

  // Admin routes for Items
  async getItemsAdmin(queries) {
    return  this.execute('post', 'items-admin', queries)
  },
  async getItemAdmin(item_id) {
    return  this.execute('get', 'item-admin/' + item_id)
  },
  async publishItem(item) {
    return  this.execute('post', 'item-publish', item)
  },
  async makeItemPublic(item_id) {
    return  this.execute('get', 'item-make-public/' + item_id)
  },
  async makeItemHidden(item_id) {
    return  this.execute('get', 'item-make-hidden/' + item_id)
  },
  async deleteItemAdmin(item_id) {
    return  this.execute('delete', 'item-admin/' + item_id)
  },

  async getNewJobs (id, urlsList) {
    let resource = 'crawl/' + id
    let data = urlsList
    try {
        return (await axios({
          method: 'post',
          url: this.crawlerUrl + resource,
          data,
          headers: { 'x-access-token': this.accessToken },
        })).data
    } catch (error) {
        if (error.response && error.response.data) {
            throw error.response.data
        }
        throw error
    }
  },

  // Counter
  async getNum(type) {
    return  this.execute('get', 'num/' + type)
  },

}

export default main;
<template>
  <div>
    <!-- Dialog -->
    <v-dialog
        v-model="isDialogOpen" 
        overlay-opacity="0.9"
        persistent 
        transition="dialog-bottom-transition"
        width="1400"
        style="overflow-x: scroll;">
        <v-toolbar
            class="white--text" 
            height="40">

            <!-- Toggle vidibility -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="toggleVisibility"
                :disabled="isDeleting"
                :loading="isVisibilityChanging"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon v-if="selectedItem.visibility == 'public'" small class="white--text">mdi-eye-off</v-icon>
                <v-icon v-else small class="white--text">mdi-eye</v-icon>
            </v-btn>
            </template>
            <span v-if="selectedItem.visibility == 'public'">Make under review</span>
            <span v-else>Publish</span>
            </v-tooltip>

            <!-- publish -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="publishItem()"
                :loading="isPublishing"
                :disabled="isDeleting || isVisibilityChanging || selectedItem.visibility == 'public'"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-publish</v-icon>
            </v-btn>
            </template>
            <span>Publish officially</span>
            </v-tooltip>

            <!-- corrections -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="isCorrectionDialogOpen = true"
                :disabled="isDeleting || isVisibilityChanging"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-notebook-edit-outline</v-icon>
            </v-btn>
            </template>
            <span>Request corrections</span>
            </v-tooltip>

            <!-- Delete -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="isYesNoDialogOpen = true" 
                :disabled="isVisibilityChanging"
                :loading="isDeleting"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-delete</v-icon>
            </v-btn>
            </template>
            <span>Delete</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <!-- close -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="closeDialog()" 
                :disabled="isDeleting || isVisibilityChanging"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Close</span>
            </v-tooltip>

        </v-toolbar>
        <v-card
            width="100%"
            elevation="30"
            class="mx-auto pa-2">
            <!-- Error -->
            <h3 v-if="error"  class="error--text text-center my-2">{{error}}</h3>
            <h5 v-if="selectedItem.visibility=='revised'" :key="selectedItem.visibility">
                    Revised at : {{ (new Date(selectedItem.revised_at)).toLocaleString('en-CA', {hour12: false}) }}
            </h5>
            <!-- Header -->
            <v-toolbar dense rounded class="white--text" :color="headerColor()">
                <v-icon class="mr-1" dark>mdi-account-circle</v-icon> Creator: {{selectedItem.creator}}
                <v-spacer></v-spacer>
                <v-icon class="mr-1" dark>mdi-eye</v-icon> {{selectedItem.views}} views
            </v-toolbar>
            <v-layout wrap class="px-6 py-3">
                <!-- Image -->
                <v-flex  xs12 md4 class="text-center pa-10" align-self-center>
                          <v-img
                              v-if="imageSrc"
                              contain
                              height="300"
                              width="450"
                              class="mx-auto"
                              :src="imageSrc">
                              <template v-slot:placeholder>
                                <v-sheet 
                                    height="300"
                                    max-width="450"
                                    color="primary lighten-1"
                                    elevation="30"
                                    shaped
                                    class="mx-auto text-center d-flex align-center">
                                    <h4 class="mx-auto text-h5 white--text">No Image</h4>
                                </v-sheet>
                              </template>
                          </v-img>
                          <p v-else class="text-center">No Image</p>
                </v-flex>
                <!-- 3D model -->
                <v-flex  xs12 md4 class="text-center pa-10" align-self-center>
                        <v-responsive 
                            v-if="selectedItem.link3d"
                            :aspect-ratio="16/9"
                            width="540"
                            class="mx-auto"
                            style="border: 1px solid grey">
                            <iframe 
                                :key="selectedItem.link3d"
                                :src="selectedItem.link3d" 
                                id="iframeid" 
                                referrerpolicy="origin" 
                                class="render-view" 
                                style="border: 0; width: 100%; height: 100%;" 
                                allowFullScreen="true" 
                                tabindex="-1">
                            </iframe>
                        </v-responsive>
                        <p v-else class="text-center">No 3D model</p>
                </v-flex>
                <!-- information -->
                <v-flex xs12 md4 align-self-center class="my-2">
                      <p class="mx-1 my-2 text-center">
                          <span class="font-weight-bold text-h5">{{selectedItem.title}}</span> 
                      </p>
                      <v-divider></v-divider>
                      <v-layout row class="mx-1 my-2">
                          <span class="font-weight-bold"><v-icon>mdi-factory</v-icon> Category: </span>
                          <v-spacer></v-spacer> {{selectedItem.industry}} - {{selectedItem.field}}
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout row class="mx-1 my-2">
                          <span class="font-weight-bold"><v-icon>mdi-cash-multiple</v-icon> Price: </span> 
                          <v-spacer></v-spacer> 
                          <span v-if="selectedItem.price_unit != 'Call me'" class="mr-2"> {{ selectedItem.price }} </span> 
                          {{selectedItem.price_unit}}
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout row  class="mx-1 my-2">
                          <span class="font-weight-bold"><v-icon>mdi-cellphone</v-icon> Connect with: </span>
                          <v-spacer></v-spacer> {{selectedItem.connectwith}}
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout row  class="mx-1 my-2">
                          <span class="font-weight-bold"><v-icon>mdi-calendar</v-icon> Posted at: </span>
                          <v-spacer></v-spacer> 
                          {{ (new Date(selectedItem.created_at)).toLocaleString('en-CA', {hour12: false}) }}
                      </v-layout>
                      <v-divider v-if="selectedItem.link"></v-divider>
                      <v-btn
                           v-if="selectedItem.link"
                            @click="openPage(selectedItem.link)"
                            color="primary my-4"
                            small
                            >More information
                      </v-btn>
                </v-flex>
            </v-layout>

            <!-- Description -->
            <v-divider></v-divider>
            <p class="mx-1 my-2">
            <span class="font-weight-bold"><v-icon>mdi-note-edit-outline</v-icon> Description: </span>
            </p>
            <p class="mx-1 my-2" v-html="selectedItem.description"></p>

        </v-card>
    </v-dialog>

    <!-- yesNo Dialog --> 
    <yesNoDialog  v-model="isYesNoDialogOpen" 
                  :key="isYesNoDialogOpen.toString()+'yesNoDialog'" 
                  :YesNoDialogText="'Delete the product?'" 
                  :isLoading="false" 
                  @runParentFunction="deleteItem()" />

    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

    <!-- correctionDialog -->
    <correctionDialog 
            v-model="isCorrectionDialogOpen" 
            :selectedItem="selectedItem"
            :key="isCorrectionDialogOpen.toString()+'isCorrectionDialogOpen'"
            @runParentFunction="reviseItem()"/>

  </div>
</template>

<script>
import snackbar          from  './snackbar.vue'
import correctionDialog  from  './correctionDialog.vue'
import yesNoDialog       from  './yesNoDialog.vue'
import serverGateway     from  '../modules/serverGateway'
import api               from  '../modules/api'
export default {
  name: 'showItemsDialog',
  components: { snackbar, correctionDialog, yesNoDialog },
  props: ['value', 'selectedItem', 'items'],
  data() {
    return {
        isDialogOpen: this.value,
        isPublishing: false,
        isVisibilityChanging: false,
        isDeleting: false,
        isCorrectionDialogOpen: false,
        isYesNoDialogOpen: false,
        error: null,
        imageSrc: null,
        snacktext: 'Please wait...', 
        snackbar: false
    }
  },

  methods: {

    async toggleVisibility() {
        this.isVisibilityChanging= true
        if(this.selectedItem.visibility == 'public'){
            await this.makeHidden()
            this.isVisibilityChanging= false
        }else{
            await this.makePublic()
            this.isVisibilityChanging= false
        }
    },

    async publishItem() {
        this.isPublishing = true
        try {
            this.snacktext = await serverGateway.publishItem(this.selectedItem);
            this.snackbar  = true;
            let index = this.items.findIndex(item => item._id == this.selectedItem._id );
            if(index >= 0)  this.items[index].visibility = 'public';
            this.selectedItem.visibility = 'public';
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true 
        }
        this.isPublishing = false
    },

     async makePublic() {
        try {
            this.snacktext = await serverGateway.makeItemPublic(this.selectedItem._id)
            this.snackbar  = true 
            let index = this.items.findIndex(item => item._id == this.selectedItem._id )
            if(index >= 0)  this.items[index].visibility = 'public'
            this.selectedItem.visibility = 'public'
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true 
        }
    },

    async makeHidden() {
        try {
            this.snacktext = await serverGateway.makeItemHidden(this.selectedItem._id)
            this.snackbar  = true 
            let index = this.items.findIndex(item => item._id == this.selectedItem._id )
            if(index >= 0)   this.items[index].visibility = 'hidden'
            this.selectedItem.visibility = 'hidden'
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true 
        }
    },

     async deleteItem() {
        this.isDeleting = true
        try {
            this.snacktext =  await serverGateway.deleteItemAdmin(this.selectedItem._id)
            this.snackbar  = true
            let index = this.items.findIndex(item => item._id == this.selectedItem._id )
            if(index >= 0)  this.items.splice(index, 1)
            this.isDeleting = false
            this.closeDialog()
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true
            this.isDeleting = false
        }
    },

    async reviseItem() {
        this.selectedItem.visibility = 'revised';
        this.selectedItem.revised_at = Date.now();
        let index = this.items.findIndex(item => item._id == this.selectedItem._id )
        if(index >= 0){
            this.items[index].visibility = 'revised';
            this.items[index].revised_at = this.selectedItem.revised_at;
        }
    },

    headerColor(){
        if (this.selectedItem.visibility == 'public') return 'green';
        if (this.selectedItem.visibility == 'hidden') return 'blue';
        if (this.selectedItem.visibility == 'revised') return 'orange';
    },

    closeDialog(){
        this.isDialogOpen= false
        this.$emit('input', this.isDialogOpen)
        this.$router.replace('/products')
    },
     
    openPage(url){
      window.open(url , "_blank")
    },

    loadThumbnail(){
        if(this.selectedItem.image != 'no image'){ 
            this.imageSrc = `${api.baseUrl}uploaded-images/${this.selectedItem._id}.jpeg`
        }else{
            this.imageCouldntGetLoaded = true
        }
    }

  },

  created(){
      this.loadThumbnail()
  }

}
</script>
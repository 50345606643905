<template>
  <div class="text-center">
  <div  class="mx-2 mx-sm-10 mb-16 my-12">
      <h1 class="my-12 text-center">Page is not found 🤕</h1>
      <h2 class="my-12 text-center">
        <v-btn
          @click="gotoPage('/')"
          color="primary"
          text>
          Go to Home
        </v-btn>
      </h2>
  </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data () {
    return {
    }
  },

  methods:{
    gotoPage(address){
      this.$router.push(address)
    }
  }

}
</script>

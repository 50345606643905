<template>
  <div>

      <v-sheet
          v-intersect="onIntersect"
          class="ma-1">
          <!-- Error -->
          <h3 v-if="error"  class="error--text text-center my-1">{{error}}</h3>
          <div :key="refreshKey">
              <div v-if="Object.keys(items[index]).length > 1"
                   :class="isExpired ? 'expiredClass' : ''">
                <v-hover  v-slot="{ hover }">
                    <v-card class="d-flex flex-column"
                            min-height="160"
                            @click="isDialogOpen= true"
                            :elevation="hover ? 20 : 5"
                            style="transition: 300ms linear;">
                            <!-- Expiring div -->
                            <div 
                                v-if="isExpired" 
                                class="backgroundText text-h2 font-weight-black">
                                Expired
                            </div>
                            <!-- General information -->
                            <h5 v-if="items[index].visibility == 'hidden'"  class="white--text blue text-center">Under review</h5>
                            <h5 v-if="items[index].visibility == 'public'"  class="white--text green text-center">Published</h5>
                            <v-card-text>
                                  <!-- Title -->
                                  <h3 class="text-center"
                                      :class="isExpired ? 'expiredClass' : ''">
                                      <v-icon v-if="items[index].type == 'Industrial'"
                                              :class="isExpired ? 'expiredClass' : ''">
                                              mdi-account-hard-hat-outline
                                      </v-icon>
                                      <v-icon v-else
                                              :class="isExpired ? 'expiredClass' : ''">
                                              mdi-account-school-outline
                                      </v-icon>
                                      {{items[index].title}}
                                  </h3>
                                  <v-divider class="my-1"></v-divider>
                                  <h3 class="text-body-2 text-center text-md-left mt-3"
                                      :class="isExpired ? 'expiredClass' : ''">
                                      <v-icon size="20" :class="isExpired ? 'expiredClass' : ''">
                                              mdi-office-building
                                      </v-icon>
                                      <span class="hidden-sm-and-down font-weight-bold ml-1 pt-1">Employer:</span>
                                      {{items[index].provider}}
                                  </h3>
                                  <h3 class="text-body-2 text-center text-md-left"
                                      :class="isExpired ? 'expiredClass' : ''">
                                      <v-icon size="20" :class="isExpired ? 'expiredClass' : ''">
                                              mdi-map-marker-outline
                                      </v-icon>
                                      <span class="hidden-sm-and-down font-weight-bold ml-1 pt-1">Location:</span>
                                      {{items[index].location}}
                                  </h3>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <!-- Date informations -->
                            <v-card-actions :class="isExpired ? 'expiredClass' : ''">
                              <v-layout wrap class="text-center">
                                <!-- Posted at -->
                                <v-flex xs12 sm6 md12 lg6 class="text-body-2 text-center text-md-left">
                                    <v-icon size="18" :class="isExpired ? 'expiredClass' : ''">
                                            mdi-calendar
                                    </v-icon> 
                                    <span class="hidden-sm-and-down ml-1">Posted at:</span>
                                    {{ (new Date(items[index].created_at)).toLocaleString('en-CA', {hour12: false}) }}
                                </v-flex>
                                <!-- Expires in -->
                                <v-flex xs12 sm6 md12 lg6 class="text-body-2 text-center text-md-left">
                                    <v-icon size="18" :class="isExpired ? 'expiredClass' : ''">
                                            mdi-calendar-clock
                                    </v-icon> 
                                    <span class="hidden-sm-and-down ml-1">Expires in: </span>
                                    <span v-if="items[index].expires_in == 0"> Unspecified</span>
                                    <span v-else> {{ (new Date(items[index].expires_in)).toLocaleString('en-CA', {hour12: false}) }}</span>
                                </v-flex>
                              </v-layout>
                            </v-card-actions>
                    </v-card>
                </v-hover>
              </div>
              <v-card v-else
                      class="d-flex flex-column"
                      min-height="160">
                      <v-card-text>
                            <!-- Skeleton loader -->
                            <v-skeleton-loader
                              width="100%"
                              class="mx-auto my-3" 
                              type="text@4">
                            </v-skeleton-loader>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <v-card-actions>
                            <!-- Skeleton loader -->
                            <v-skeleton-loader
                              width="100%"
                              class="my-3" 
                              type="text@1">
                            </v-skeleton-loader>
                      </v-card-actions>
              </v-card>
          </div>
      </v-sheet>

      <!-- snackbar -->
      <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import snackbar         from  './snackbar'
import serverGateway    from  '../modules/serverGateway'
export default {
  name: 'showJobs',
  components: {  snackbar  },
  props: ['items', 'index'],
  data() {
    return {
        isDialogOpen: false,
        isExpired: false,
        thumbnailSrc: null,
        imageItem: null,
        refreshKey: false,
        error: null,
        snacktext: "Please wait...", 
        snackbar: false
    }
  },

  methods: {

    async onIntersect(entries){
        this.Intersectflag = entries[0].isIntersecting
        if(this.Intersectflag && Object.keys(this.items[this.index]).length < 2 ){
            try {
                this.items[this.index] = await serverGateway.getJobAdmin(this.items[this.index]._id)
                if(this.items[this.index].expires_in < Date.now() && this.items[this.index].expires_in > 0){ this.isExpired = true } 
                if(this.items[this.index] && this.items[this.index].description){
                    this.refreshKey= !this.refreshKey
                }
            } catch (error) {
                this.error    = error+ ' 🤕'
                this.snacktext= this.error
                this.snackbar = true
            }
        }
    },

    postTime(){
      let timeDifference = (Date.now()-(new Date(this.items[this.index].created_at)).getTime())/(1000*60)
      if(timeDifference < 30) return 'Posted a few minutes ago'
      if(timeDifference >= 30 && timeDifference < 180) return 'Posted a few hrs ago'
      if(timeDifference >= 180 && timeDifference < 1440) return 'Posted 24 hrs ago'
      if(timeDifference >= 1440 && timeDifference < 10080) return 'Posted this week'
      if(timeDifference >= 10080 && timeDifference < 43200) return 'Posted this month'
      if(timeDifference >= 43200) return 'Posted a long time ago'
    },
    
  }
}
</script>

<style scoped>
.backgroundText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-20deg);
  -webkit-transform: translate(-50%, -50%) rotate(-20deg);
  color: rgba(255, 0, 0, 0.351);
}
.expiredClass{
  color: rgba(128, 128, 128, 0.644);
}
</style>
<template>
  <div>
    <!-- Dialog -->
    <v-dialog
        v-model="isDialogOpen" 
        overlay-opacity="0.9"
        persistent 
        transition="dialog-bottom-transition"
        width="1400"
        style="overflow-x: scroll;">
        <v-toolbar
            class="white--text" 
            height="40">

            <!-- Toggle vidibility -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="toggleVisibility"
                :disabled="isDeleting || isSaving"
                :loading="isVisibilityChanging"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon v-if="jobItem.visibility == 'hidden'" small class="white--text">mdi-eye</v-icon>
                <v-icon v-else small class="white--text">mdi-eye-off</v-icon>
            </v-btn>
            </template>
            <span v-if="jobItem.visibility == 'hidden'">Make visiable</span>
            <span v-else>Make invisiable</span>
            </v-tooltip>

            <!-- publish -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="publishJob()"
                :loading="isPublishing"
                :disabled="isDeleting || isVisibilityChanging || jobItem.visibility == 'public'"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-publish</v-icon>
            </v-btn>
            </template>
            <span>Publish officially</span>
            </v-tooltip>

            <!-- Update -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="saveChanges()"  
                :disabled="isDeleting || isVisibilityChanging"
                :loading="isSaving"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-content-save-edit</v-icon>
            </v-btn>
            </template>
            <span>Update</span>
            </v-tooltip>

            <!-- Delete -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="isYesNoDialogOpen = true" 
                :disabled="isSaving || isVisibilityChanging"
                :loading="isDeleting"
                class="mr-1 primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-delete</v-icon>
            </v-btn>
            </template>
            <span>Delete</span>
            </v-tooltip>
            
            <v-spacer></v-spacer>

            <!-- close -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="closeDialog()" 
                :disabled="isDeleting || isSaving || isVisibilityChanging"
                class="primary"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon small class="white--text">mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Close</span>
            </v-tooltip>
            
        </v-toolbar>
        <v-card
            width="100%"
            elevation="30"
            class="mx-auto px-2 py-1">
            <!-- Expiring div -->
            <div 
                v-if="isExpired" 
                class="backgroundText text-h1 font-weight-black">
                Expired
            </div>
            <!-- Header -->
            <v-toolbar dense rounded class="white--text" :color="headerColor()">
                <v-icon class="mr-1" dark>mdi-account-circle</v-icon> Creator: {{jobItem.creator}}
                <v-spacer></v-spacer>
                <v-icon class="mr-1" dark>mdi-eye</v-icon> {{jobItem.views}} views
            </v-toolbar>
            <v-form
                v-model="isFormValid"
                ref="form"
                class="mx-sm-6 pa-sm-2"
                lazy-validation>
                <v-layout wrap>
                    <v-flex xs12 sm6 class="px-2">
                            <!-- Title -->
                            <span>Title:</span>
                            <v-text-field
                                v-model="jobItem.title"
                                :rules="textRules"
                                outlined
                                dense
                            ></v-text-field>
                            <!-- Type -->
                            <span>Type:</span>
                            <v-select 
                                v-model="jobItem.type"
                                :rules="textRules"
                                dense 
                                outlined
                                :items="['Academic', 'Industrial']">
                            </v-select>
                            <!-- provider -->
                            <span>Employer:</span>
                            <v-text-field
                                v-model="jobItem.provider"
                                :rules="textRules"
                                outlined
                                dense
                            ></v-text-field>
                            <!-- Location -->
                            <span>Location:</span>
                            <v-text-field
                                v-model="jobItem.location"
                                :rules="textRules"
                                outlined
                                dense
                                hide-spin-buttons
                            ></v-text-field>
                            <!-- Salary -->
                            <v-layout :class="!isThereSalary ? 'mb-5' : 'mb-0'">
                                <span class="mr-2">Salary: </span>
                                <v-radio-group v-model="isThereSalary" row class="ma-0 pa-0" hide-details>
                                    <v-radio
                                        label="Unspecified"
                                        :value="false"
                                        @click="jobItem.salary = 0"
                                        class="my-0 mr-3"
                                        hide-details
                                    ></v-radio>
                                    <v-radio
                                        label="Insert a value"
                                        :value="true"
                                        class="my-0 mr-3"
                                        hide-details
                                        ></v-radio>
                                </v-radio-group>
                            </v-layout>
                            <!-- Salary field -->
                            <v-scroll-y-transition hide-on-leave>
                            <v-text-field
                                v-if="isThereSalary"
                                :key="isThereSalary"
                                v-model="jobItem.salary"
                                :rules="textRules"
                                validate-on-blur
                                outlined
                                dense
                                type="number"
                                hide-spin-buttons
                            ></v-text-field>
                            </v-scroll-y-transition>
                            <!-- Salary unit -->
                            <v-scroll-y-transition hide-on-leave>
                            <div :key="isThereSalary" v-if="isThereSalary">
                                <span >Salary Unit:</span>
                                <v-select
                                    v-model="jobItem.salary_unit"
                                    :rules="textRules"
                                    append-icon="mdi-minus"
                                    dense 
                                    outlined
                                    :items="['USD/hr', 'USD/month', 'USD/year', '€/hr', '€/month', '€/year']">
                                </v-select>
                            </div>
                            </v-scroll-y-transition>

                            <!-- Expires in -->
                            <v-layout :class="!isThereDeadline ? 'mb-5' : 'mb-0'">
                                <span class="mr-2">Expires in: </span>
                                <v-radio-group v-model="isThereDeadline" row class="ma-0 pa-0" hide-details>
                                    <v-radio
                                        label="Unspecified"
                                        :value="false"
                                        @click="makeUnspecified()"
                                        class="my-0 mr-3"
                                        hide-details
                                    ></v-radio>
                                    <v-radio
                                        label="Pick a date"
                                        :value="true"
                                        @click="isTimePickerDialogOpen = true"
                                        class="my-0 mr-3"
                                        hide-details
                                        ></v-radio>
                                </v-radio-group>
                            </v-layout>
                            <!-- Date and change btn -->
                            <v-scroll-y-transition hide-on-leave>
                            <v-layout v-if="isThereDeadline" :key="isThereDeadline" class="my-2" align-content-center>
                                <v-btn
                                    @click="isTimePickerDialogOpen = true"
                                    rounded
                                    color="primary"
                                    class="normalBtnText">
                                    {{expiringValue(jobItem.expires_in)}}
                                    <v-icon right>mdi-calendar-clock</v-icon>
                                </v-btn>
                            </v-layout>
                            </v-scroll-y-transition>

                    </v-flex>
                    <v-flex xs12 sm6 class="px-2">
                            <!-- Link -->
                            <span><a :href="jobItem.link" target="_blank">Link (optional):</a></span>
                            <v-text-field
                                v-model="jobItem.link"
                                outlined
                                dense
                                hide-spin-buttons
                            ></v-text-field>
                            <!-- description -->
                            <span>Description:</span>
                            <v-textarea
                                v-model="jobItem.description"
                                :rules="textRules"
                                outlined
                                rows="18"
                                no-resize
                            ></v-textarea>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- yesNo Dialog --> 
    <yesNoDialog  v-model="isYesNoDialogOpen" 
                    :key="isYesNoDialogOpen.toString()+'yesNoDialog'" 
                    :YesNoDialogText="'Delete the job?'" 
                    :isLoading="false" 
                    @runParentFunction="deleteJob()" />

    <!-- time Picker Dialog -->
    <timePickerDialog   v-model="isTimePickerDialogOpen" 
                        :currentDate="returnTime(jobItem.expires_in)"
                        :key="isTimePickerDialogOpen.toString()+'timepicker'"
                        @runParentFunction="changeDeadline($event)"/>

    <!-- snackbar -->
    <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import serverGateway    from  '../modules/serverGateway'
import snackbar         from  './snackbar.vue'
import yesNoDialog      from  './yesNoDialog.vue'
import timePickerDialog from  './timePickerDialog.vue'
export default {
  name: 'showJobsDialog',
  props: ['value','jobItem', 'items'],
  components: { snackbar, yesNoDialog, timePickerDialog },
  data() {
    return {
        isDialogOpen: this.value,
        isFormValid: true,
        isYesNoDialogOpen: false,
        isThereSalary: false,
        isThereDeadline: false,
        isSaving: false,
        isDeleting: false,
        isPublishing: false,
        isVisibilityChanging: false,
        isExpired: false,
        isTimePickerDialogOpen: false,
        textRules: [  v => !!v || 'This field is required', ],
        snacktext: 'Please wait...',
        snackbar: false,
        error: null
    }
  },

  methods: {

    async toggleVisibility() {
        this.isVisibilityChanging= true
        if(this.jobItem.visibility == 'public'){
            await this.makeHidden()
            this.isVisibilityChanging= false
        }else{
            await this.makePublic()
            this.isVisibilityChanging= false
        }
    },

    async publishJob() {
        this.isPublishing = true
        try {
            this.snacktext = await serverGateway.publishJob(this.jobItem)
            console.log(this.snacktext)
            this.snackbar  = true 
            let index = this.items.findIndex(item => item._id == this.jobItem._id )
            if(index >= 0) this.items[index].visibility = 'public'
            this.jobItem.visibility = 'public'
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true 
        }
        this.isPublishing = false
    },

    async makePublic() {
        try {
            this.snacktext = await serverGateway.makeJobPublic(this.jobItem._id)
            this.snackbar  = true 
            this.jobItem.visibility = 'public'
            let index = this.items.findIndex(item => item._id == this.jobItem._id )
            if(index >= 0) this.items[index].visibility = 'public'
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true 
        }
    },

    async makeHidden() {
        try {
            this.snacktext = await serverGateway.makeJobHidden(this.jobItem._id)
            this.snackbar  = true 
            this.jobItem.visibility = 'hidden'
            let index = this.items.findIndex(item => item._id == this.jobItem._id )
            if(index >= 0) this.items[index].visibility = 'hidden'
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true 
        }
    },

    closeDialog(){
        this.isDialogOpen= false
        this.$emit('input', this.isDialogOpen)
        this.$router.replace('/jobs')
    },

    returnTime(T){
        return (new Date(T).getFullYear()).toString() + '-' + (new Date(T).getMonth()+1).toString() + '-' + (new Date(T).getDate()).toString()         
    },

    changeDeadline(deadline){
        this.jobItem.expires_in = Date.parse(deadline)
        let index = this.items.findIndex(item => item._id == this.jobItem._id )
        if(index >= 0) this.items[index].expires_in = Date.parse(deadline)
        if(this.jobItem.expires_in < Date.now() && this.jobItem.expires_in > 0){ this.isExpired = true  }
                                                                           else{ this.isExpired = false }
    },
   
    makeUnspecified(){
        this.jobItem.expires_in = 0
        let index = this.items.findIndex(item => item._id == this.jobItem._id )
        if(index >= 0) this.items[index].expires_in = 0
        if(this.jobItem.expires_in < Date.now() && this.jobItem.expires_in > 0){ this.isExpired = true  }
                                                                           else{ this.isExpired = false }
    },

    expiringValue(Time){
        return (new Date(Time)).toISOString().split('T')[0]
    },

    reset(){
      this.$refs.form.reset()
    },

    saveChanges(){
        this.$refs.form.validate()
        this.$nextTick(() => {
          if(this.isFormValid){
                if(this.jobItem._id){ 
                    this.updateJob() 
                }else{
                    this.insertJob() 
                }
          }else{
            this.snacktext= 'Please correct the red fields'
            this.snackbar= true
          }
        })
    },
    
    async insertJob() {
        this.isSaving = true
        try {
            this.snacktext =  await serverGateway.insertJob(this.jobItem)
            this.snackbar  = true
            this.isSaving = false
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true
            this.isSaving = false
        }
    },
    
    async updateJob() {
        this.isSaving = true
        try {
            this.snacktext =  await serverGateway.updateJob(this.jobItem)
            let index = this.items.findIndex(item => item._id == this.jobItem._id )
            if(index >= 0) this.items[index] = this.jobItem
            this.snackbar  = true
            this.isSaving = false
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true
            this.isSaving = false
        }
    },

    async deleteJob() {
        this.isYesNoDialogOpen = false
        this.isDeleting = true
        try {
            this.snacktext =  await serverGateway.deleteJob(this.jobItem._id)
            this.snackbar  = true
            let index = this.items.findIndex(item => item._id == this.jobItem._id )
            if(index >= 0) this.items.splice(index, 1)
            this.isDeleting = false
            this.closeDialog()
        } catch (error) {
            this.error     = error
            this.snacktext = this.error
            this.snackbar  = true
            this.isDeleting = false
        }
    },

    headerColor(){
        if (this.jobItem.visibility == 'public') return 'green';
        if (this.jobItem.visibility == 'hidden') return 'blue';
    },

  },

  created(){
    if(this.jobItem.salary > 0 )     this.isThereSalary   = true
    if(this.jobItem.expires_in > 0 ) this.isThereDeadline = true
    if(this.jobItem.expires_in < Date.now() && this.jobItem.expires_in > 0){ this.isExpired = true }
  },

}
</script>
<style scoped>
  .backgroundText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);
    -webkit-transform: translate(-50%, -50%) rotate(-20deg);
    color: rgba(255, 0, 0, 0.351);
  }
  .expiredClass{
  color: rgba(128, 128, 128, 0.644);
}
  </style>
<template>
  <div>
      <!-- Error -->
      <h3 v-if="error"  class="error--text text-center my-1">{{error}}</h3>
      <v-sheet 
          v-if="me" 
          class="text-center">
          <h2 class="text-center my-10">Welcome to your profile</h2>
      </v-sheet>
      
      <v-layout wrap>
        <v-flex xs12 sm6 v-if="me">
              <h3 class="ml-14 my-2">Personal Information:</h3>
              <p class="ml-14 my-2">
                  <span class="font-weight-bold">Name: </span>{{me.first_name}}
              </p>
              <p class="ml-14 my-2">
                  <span class="font-weight-bold">Surname: </span>{{me.last_name}}
              </p>
              <p class="ml-14 my-2">
                  <span class="font-weight-bold">Email: </span>{{me.email}}
              </p>
        </v-flex>
        <v-flex xs12 sm6>
              <h3 class="ml-14 my-2">Setting:</h3>
              <v-spacer></v-spacer>
              <v-btn @click="changetheme" class="ml-14 my-1" plain>
                  <v-icon class="mr-1">mdi-theme-light-dark</v-icon>
                  Theme
              </v-btn>
              <v-spacer></v-spacer>
              <SignOutDialog class="ml-14 my-1"/>
        </v-flex>
      </v-layout>

    <!-- Snackbar -->
    <v-snackbar 
      v-model="snackbar" 
      :timeout="3500" 
      color="blue darken-4">
      <div class="text-center subtitle-1">{{snacktext}}</div>
    </v-snackbar>
  </div>
</template>

<script>
import serverGateway    from  '../modules/serverGateway'
import SignOutDialog    from  '../components/SignOutDialog'
export default {
  name: 'UserProfile',
  components: { SignOutDialog },
  data () {
    return {
      items: [],
      likes:[],
      isSearching: false,
      isUserSignedIn: true,
      showMyItems: false,
      me: null,
      snacktext: 'Please wait...', 
      snackbar: false, 
      error: null
    }
  },

  methods: {

    changetheme() {    
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.theme = this.$vuetify.theme.dark
    }

  },

  async created(){
      this.isSearching = true
      let response = await serverGateway.ensureInitialized()
      if(response){
        this.isUserSignedIn = true
        this.me = await serverGateway.findMe()
      }else{
          this.$router.push('/login')
      }
      this.isSearching = false
  }
}
</script>

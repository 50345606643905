<template>
  <v-dialog 
      v-model="isLoadingDialogOpen" 
      overlay-opacity="0.9" 
      overlay-color="success darken-1"
      persistent 
      width="300">
      <v-card 
          class="text-center pa-2">
          <span>
                <v-progress-circular
                  indeterminate
                  :size="21"
                  class="mr-6"
                ></v-progress-circular>
                {{header}}
          </span>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'loadingDialog',
  props: ['isLoadingDialogOpen', 'header'],
}
</script>
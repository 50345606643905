<template>
<div>
    <!-- Toolbar -->
    <v-app-bar 
        app 
        clipped-right 
        class="white--text"
        color="background"
        dense>
        <v-app-bar-nav-icon class="white--text"
            @click="isDrawerOpen = !isDrawerOpen">
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <!-- Title -->
        <v-toolbar-title  
            class="hidden-md-and-up">Energy Trading
        </v-toolbar-title>
        <v-scale-transition hide-on-leave>
          <v-toolbar-title
              :key="nameofCurrentRoute" 
              class="hidden-sm-and-down">{{nameofCurrentRoute}}
          </v-toolbar-title>
        </v-scale-transition>
        <v-spacer></v-spacer>
        <!-- Profile icon -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              to="/user-profile"
              icon
              class="white--text"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Your profile</span>
        </v-tooltip>
        <!-- extension -->
        <template v-slot:extension>
          <!-- Tabs -->
          <v-tabs centered class="hidden-sm-and-down">
            <v-tab v-for="(link, index) in links"
                  :key="index"
                  :to="link.route"
                  class="white--text normalBtnText">{{link.text}}
            </v-tab>
          </v-tabs>
          <!-- Title -->
          <v-scale-transition hide-on-leave>
          <v-toolbar-title 
              :key="nameofCurrentRoute" 
              class="text-uppercase hidden-md-and-up mx-auto">
              <span>{{nameofCurrentRoute}}</span>
          </v-toolbar-title>
          </v-scale-transition>
      </template>
    </v-app-bar>
    <!-- Navigation drawer -->
    <v-navigation-drawer 
        v-model="isDrawerOpen" 
        class="justify-center"
        fixed 
        temporary 
        left 
        width="320" 
        overlay-opacity="0.7">
        <v-list>
            <v-list-item-group>
                <v-list-item
                    v-for="(link, index) in links"
                    :key="index"
                    :to="link.route">
                    <v-list-item-icon>
                      <v-icon large v-text="link.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="link.text"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>

    <!-- Snackbar -->
    <v-snackbar 
        v-model="snackbar" 
        :timeout="7000"
        color="primary">
        <v-expand-transition>
        <div  :key="snackbar"
              class="text-center">{{snacktext}} 
              <v-icon 
                @click="snackbar=false" 
                small>mdi-close
              </v-icon>
        </div>
        </v-expand-transition>
    </v-snackbar>
</div>
</template>

<script>
export default {
  name: 'Navbar',
  props: ['nameofCurrentRoute'],
  data () {
    return {
      isDrawerOpen: false,
      links: [
          { icon: 'mdi-home-circle-outline',      text: 'Home',               route: '/' },
          { icon: 'mdi-account-multiple-outline', text: 'Mange users',        route: '/users' },
          { icon: 'mdi-note',                     text: 'Mange requests',     route: '/requests' },
          { icon: 'mdi-package-variant',          text: 'Mange products',     route: '/products' },
          { icon: 'mdi-briefcase-outline',        text: 'Manage jobs',        route: '/jobs' }
      ],
      snacktext: 'Please wait...', 
      snackbar: false
    }
  }
}
</script>
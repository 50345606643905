<template>
    <v-navigation-drawer
      app
      v-model="isDrawerOpen"
      width="300"
      clipped
      class="pa-4"
      right>
      <h3 class="text-center my-2">Filter Jobs</h3>
      <v-divider class="mb-2"></v-divider>
            <!-- Type -->
            <v-select 
                v-model="queries.visibility"
                label="Visibility"
                class="my-5" 
                hide-details 
                dense 
                outlined
                rounded
                :items="['Any visibility', 'hidden', 'public']">
            </v-select>
            <!-- Type -->
            <v-select 
                v-model="queries.type"
                label="Type of Job"
                class="my-5" 
                hide-details 
                dense 
                outlined
                rounded
                :items="['Any type', 'Academic', 'Industrial']">
            </v-select>
            <!-- Key word -->
            <v-text-field
                v-model="queries.keyWord"
                v-on:keyup.enter="search()"
                clearable
                label="Key word"
                class="my-5"
                hide-details
                dense 
                outlined
                rounded>
            </v-text-field>
            <!-- Employer -->
            <v-text-field
                v-model="queries.provider"
                v-on:keyup.enter="search()"
                clearable
                label="Employer"
                class="my-5"
                hide-details
                dense 
                outlined
                rounded>
            </v-text-field>
            <!-- Location -->
            <v-text-field
                v-model="queries.location"
                v-on:keyup.enter="search()"
                clearable
                label="Location"
                class="my-5"
                hide-details
                dense 
                outlined
                rounded>
            </v-text-field>
            <!-- Time order -->
            <v-radio-group
                v-model="queries.timeInterval">
                <v-radio
                  label="Newest"
                  value="DESC"
                ></v-radio>
                <v-radio
                  label="Oldest"
                  value="ASC"
                ></v-radio>
            </v-radio-group>
            <!-- Search btn -->
            <v-btn 
                @click="search"
                class="mx-1 my-5 normalBtnText" 
                color="primary"
                rounded>
                Search
            </v-btn>
            <v-btn 
                @click="reset"
                class="mx-1 my-5 normalBtnText" 
                outlined
                rounded>
                Reset
            </v-btn>
    </v-navigation-drawer>
</template>

<script>
export default {
  name: 'jobsSearchBox',
  props: ['value', 'initialQueries'],
  data() {
    return {
        isDrawerOpen: this.value,
        queries: JSON.parse(JSON.stringify(this.initialQueries)),
        error: null,
    }
  },

  methods: {

    async search(){
      if(this.queries.keyWord  == null) this.queries.keyWord = '';
      if(this.queries.provider == null) this.queries.provider = '';
      if(this.queries.location == null) this.queries.location = '';
      this.$emit('sendDatatoParent', this.queries)
    },

    reset(){
        if( JSON.stringify(this.queries) != JSON.stringify(this.initialQueries) ){
            this.queries = JSON.parse(JSON.stringify(this.initialQueries));
            this.search();
        }
    }

  },

}
</script>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
      themes: {
        light: {
            primary: '#1565C0',
            success: '#EEEEEE',
            background: '#1565C0',
            info: '#424242',
            error: '#f83e70'
        },
        dark: {
            primary: '#424242',
            success: '#616161',
            background: '#000000',
            info: '#f5f5f5',
            error: '#EF9A9A'
        }
      }
    }
  });

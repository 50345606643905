<template>
    <v-navigation-drawer
      app
      v-model="isDrawerOpen"
      width="300"
      clipped
      class="pa-4"
      right>
      <h3 class="text-center my-2">Filter Products</h3>
      <v-divider class="my-5"></v-divider>
            <!-- Visibility -->
            <v-select 
                v-model="queries.visibility"
                @change="selectFields"
                label="Visibility"
                class="my-5" 
                hide-details 
                dense 
                outlined
                rounded
                :items="['Any visibility', 'hidden', 'public', 'revised']">
            </v-select>
            <!-- Key word -->
            <v-text-field
                v-model="queries.keyWord"
                v-on:keyup.enter="search()"
                clearable
                label="Key word"
                class="my-5"
                hide-details
                dense 
                outlined
                rounded>
            </v-text-field>
            <!-- Industry -->
            <v-select 
                v-model="queries.industry"
                @change="selectFields"
                label="Industry"
                class="my-5" 
                hide-details 
                dense 
                outlined
                rounded
                :items="['Any industry', 'Green Energy', 'Petroleum']">
            </v-select>
            <!-- Field -->
            <v-select 
                v-model="queries.field"
                :disabled="queries.industry == 'Any industry'"
                label="Field"
                class="my-5" 
                hide-details 
                dense 
                outlined
                rounded
                :items="fieldItems">
            </v-select>
            <!-- Time order -->
            <v-radio-group
                v-model="queries.timeInterval">
                <v-radio
                  label="Newest"
                  value="DESC"
                ></v-radio>
                <v-radio
                  label="Oldest"
                  value="ASC"
                ></v-radio>
            </v-radio-group>
            <!-- Search btn -->
            <v-btn 
                @click="search"
                class="mx-1 my-5 normalBtnText" 
                color="primary"
                rounded>
                Search
            </v-btn>
            <v-btn 
                @click="reset"
                class="mx-1 my-5 normalBtnText" 
                outlined
                rounded>
                Reset
            </v-btn>
    </v-navigation-drawer>
</template>

<script>
export default {
  name: 'itemsSearchBox',
  props: ['value', 'initialQueries'],
  data() {
    return {
        isDrawerOpen: this.value,
        queries: JSON.parse(JSON.stringify(this.initialQueries)),
        error: null,
        fieldItems: null,
        petroleumFields: ['Any field', 'Drilling', 'Exploration', 'Production' , 'Refinery', 'Reservoir'],
        greenFields: ['Any field', 'Biomass', 'Electrical', 'Geothermal', 'Hydrogen', 'Hydropower', 'Solar', 'Wind'],
    }
  },

  methods: {

    async search(){
        if(this.queries.keyWord == null) this.queries.keyWord = '';
        this.$emit('sendDatatoParent', this.queries)
    },

    reset(){
        if( JSON.stringify(this.queries) != JSON.stringify(this.initialQueries) ){
            this.queries = JSON.parse(JSON.stringify(this.initialQueries));
            this.search();
        }
    },

    selectFields(){
        if(this.queries.industry == 'Any industry'){ this.queries.field = 'Any field'; this.fieldItems =null }
        if(this.queries.industry == 'Petroleum')    this.fieldItems = this.petroleumFields
        if(this.queries.industry == 'Green Energy') this.fieldItems = this.greenFields
    },

  },

}
</script>
<template>
  <div class="mb-14 pb-14 mx-md-8 mx-4">
      <!-- App bar -->
      <v-app-bar
            v-if="isAppBarOpen"
            app 
            clipped-right  dense style="top: 95px;" 
            outlined>
            <v-spacer></v-spacer>
            <v-btn
                  @click="isManageJobsOpen=!isManageJobsOpen"
                  text
                  class="normalBtnText">
                  <v-icon left dark>mdi-cog-outline</v-icon>
                  Jobs setting
            </v-btn>
            <v-btn
                  @click="isSeachBoxOpen=!isSeachBoxOpen"
                  text
                  class="normalBtnText">
                  <v-icon left dark>mdi-filter-variant</v-icon>
                  Filter jobs
            </v-btn>
            <v-spacer></v-spacer>
      </v-app-bar>

      <!-- Search box -->
      <jobsSearchBox  v-model="isSeachBoxOpen" 
                      :key="isSeachBoxOpen.toString()+'seachBox'" 
                      :initialQueries="initialQueries"  
                      @sendDatatoParent="updateQueries($event)"/>
      <!-- manage Jobs -->
      <manageJobs     v-model="isManageJobsOpen" 
                      :key="isManageJobsOpen.toString()+'manageJobs'" 
                      @runParentFunction="updateQueries(queries)" />


      <!-- Error -->
      <h3 v-if="error"  class="error--text text-center my-2">{{error}}</h3>

      <!-- Jobs -->
      <v-layout
          wrap
          class="mx-2 mt-4">
          <v-fab-transition 
              group 
              class="layout wrap">
              <v-flex xs12 md6
                      v-for="(item, index) in items" 
                      v-bind:key="item._id"
                      class="my-1">
                      <v-sheet @click="openItemsDialog(index)" style="cursor:pointer">
                          <showJobs
                              :items="items" 
                              :index="index"/>
                      </v-sheet>
              </v-flex>
          </v-fab-transition>
      </v-layout>

      <!-- show Jobs Dialog -->
      <router-view  v-if="isDialogOpen" 
                    v-model="isDialogOpen" 
                    :jobItem="selectedItem"
                    :items="items">
      </router-view>

      <!-- No job is found -->
      <v-sheet
        v-if="items.length == 0 && !isSearching && !error"
          height="80vh"
          width="100%"
          class="mx-auto text-center d-flex align-center justify-center">
          <h2 class="text-center">No job is found! <br/>You can change filters</h2>
      </v-sheet>

      <!-- Go to top btn -->
      <v-fab-transition>
        <v-btn 
            v-show="scrollbtn" 
            @click="toTop"
            elevation="24"
            color="primary darken-2"
            class="mb-16 mb-md-4"
            fab 
            fixed 
            bottom 
            left>
            <v-icon size="30">mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>

      <!-- Loading new items -->
      <h5 v-if="isSearching && items.length > 0" 
          class="text-center mt-7 mb-14">
          <v-progress-circular
            indeterminate
            :size="25"
          ></v-progress-circular>
      </h5>

      <!-- loading Dialog -->
      <loadingDialog v-if="items.length == 0 && !isSearchingModal" :isLoadingDialogOpen="isSearching" :header="'Searching jobs...'" />
      <loadingDialog        :isLoadingDialogOpen="isSearchingModal" :header="'Finding desired jobs'" />

      <!-- snackbar -->
      <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>

  </div>
</template>

<script>
import serverGateway   from  '../modules/serverGateway'
import snackbar        from  '../components/snackbar.vue'
import loadingDialog   from  '../components/loadingDialog.vue'
import jobsSearchBox   from  '../components/jobsSearchBox.vue'
import manageJobs      from  '../components/manageJobs.vue'
import showJobs        from  '../components/showJobs.vue'
export default {
  name: 'Jobs',
  components: { snackbar, loadingDialog, jobsSearchBox, manageJobs, showJobs },
  data () {
    return {
      snacktext: 'Please wait...', 
      snackbar: false, 
      offset: 0,
      count: 51,
      scrollbtn: false,
      isSearching: false,
      items: [],
      selectedItem: null,
      initialQueries: {visibility: 'Any visibility', type: 'Any type', keyWord: '', provider: '', location:'', timeInterval: 'DESC'},
      queries: {visibility: 'Any visibility', type: 'Any type', keyWord: '', provider: '', location:'', timeInterval: 'DESC'},
      isDialogOpen: false,
      isSearchingModal: false,
      isAppBarOpen: false,
      isSeachBoxOpen: false,
      isManageJobsOpen: false,
      error: null
    }
  },

  methods:{

    updateQueries(queries){
       this.offset = 0
       this.queries = JSON.parse(JSON.stringify(queries))
       this.items = []
       this.getitems(this.queries)
    },

    async getitems(queries){ 
      queries.offset =  this.offset
      queries.count =  this.count
      this.isSearching= true
      setTimeout(async ()=>{

          try {
            let newItems = await serverGateway.getJobsAdmin(queries)
            this.items = this.items.concat(newItems)
            this.isSearching = false
            this.error = null
          } catch (error) {
            this.error    = error+ ' 🤕'
            this.snacktext= this.error
            this.snackbar = true
            this.isSearching = false
          }

      }, 2000)

    },

    openItemsDialog(index){
      if(Object.keys(this.items[index]).length > 2){
          this.selectedItem = this.items[index]
          this.isDialogOpen= true
          this.$router.push('jobs/'+this.items[index]._id)
      }
    },
    async checkModal(){
      this.isDialogOpen = false
      if (this.$route.params.modal) this.loadJob()
    },
    async loadJob(){
        try {
            this.isSearchingModal = true
            this.selectedItem = await serverGateway.getJobAdmin(this.$route.params.modal)
            if(this.selectedItem){
                this.selectedItem.description=this.selectedItem.description.replace(/(\r\n|\n|\r)/gm, "<br/>")
                this.isDialogOpen = true
                this.isSearchingModal = false
            }
            else{
                this.isSearchingModal = false
                this.$router.push('/jobs')
                this.snacktext= 'Wrong address'
                this.snackbar = true
            }
        } catch (error) {
            this.error    = error+ ' 🤕'
            this.snacktext= this.error
            this.snackbar = true
        }
    },
    
    toTop () {  this.$vuetify.goTo(0)  },

    onScroll() {
      if(window.scrollY > 100){   this.scrollbtn= true   }
                          else{   this.scrollbtn= false  }
      if(window.scrollY/(document.body.offsetHeight-window.innerHeight) > 0.9 && 
        this.offset+this.count <= this.items.length){
          if(!this.isSearching){
                    this.offset = this.offset + this.count
            this.getitems(this.queries)
          }
      }
    }

  },

  created(){
    this.getitems(this.queries);
    window.addEventListener("scroll", this.onScroll, false);
    this.checkModal();
    setTimeout(() => {this.isAppBarOpen = true}, 1000);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, false)
  }

}
</script>

<template>
    <div>
      <v-hover
          v-slot="{ hover }">
          <v-card 
              v-intersect="onIntersect"
              :elevation="hover ? 20 : 5"
              style="transition: 300ms linear;"
              class="ma-1 text-center">
              <!-- Error -->
              <h3 v-if="error"  class="error--text text-center my-1">{{error}}</h3>
              <div :key="refreshKey">
                  <!-- Initial data -->
                  <div v-if="Object.keys(items[index]).length > 1">
                          <h5 v-if="items[index].visibility == 'hidden'"  class="white--text blue">Under review</h5>
                          <h5 v-if="items[index].visibility == 'public'"  class="white--text green">Published</h5>
                          <h5 v-if="items[index].visibility == 'revised'" class="white--text orange">Revised</h5>
                          <v-layout>
                              <!-- Text -->
                              <v-flex xs7>
                                  <v-layout column style="height: 100%">
                                          <v-flex>
                                              <p class="font-weight-bold text-body-1 my-2"> 
                                                  {{items[index].title}}
                                              </p>
                                              <p class="text-body-2 my-1">Price: 
                                                  <span v-if="items[index].price_unit != 'Call me'">{{items[index].price}} </span> 
                                                  {{items[index].price_unit}}
                                              </p>
                                          </v-flex>
                                          <v-spacer></v-spacer>
                                          <v-flex xs2>
                                                  <p class="text-caption">
                                                    Posted at: {{ (new Date(items[index].created_at)).toLocaleString('en-CA', {hour12: false}) }}
                                                  </p>
                                          </v-flex>
                                  </v-layout>
                              </v-flex>
                              <!-- Image and 3D Model -->
                              <v-flex xs5>
                                  <v-img
                                      v-if="thumbnailSrc"
                                      height="130"
                                      width="200"
                                      @error="imageCouldntGetLoaded= true"
                                      class="mx-auto"
                                      style="overflow:visible"
                                      :src="thumbnailSrc">
                                      <template v-slot:placeholder>
                                              <v-skeleton-loader 
                                                  height="130"
                                                  width="200"
                                                  class="mx-auto"  
                                                  :boilerplate="imageCouldntGetLoaded"  
                                                  type="image">
                                              </v-skeleton-loader>
                                      </template>
                                      <v-icon v-if="items[index].link3d" 
                                         style="position:absolute; bottom: 0; left: -25px;"
                                         >mdi-cube-outline
                                      </v-icon>
                                  </v-img>
                                  <v-sheet 
                                      v-else
                                      height="130"
                                      max-width="200"
                                      color="success"
                                      elevation="5"
                                      outlined
                                      rounded
                                      class="mx-auto text-center d-flex align-center"
                                      style="position: relative;">
                                      <h4  class="mx-auto">
                                          <v-icon size="55">mdi-image-off-outline</v-icon>
                                      </h4>
                                      <v-icon v-if="items[index].link3d" 
                                         style="position:absolute; bottom: 0; left: -25px;"
                                         >mdi-cube-outline
                                      </v-icon>
                                  </v-sheet>
                              </v-flex>
                          </v-layout>
                  </div>
                  <!-- Skeleton loader -->
                  <div v-else>
                          <h5 class="white--text grey">Loading...</h5>
                          <v-layout>
                              <!-- Text -->
                              <v-flex xs7>
                                  <v-layout column style="height: 100%">
                                      <v-flex>
                                          <v-skeleton-loader  
                                          max-width="100"
                                          class="mx-auto my-1" 
                                          type="text@2">
                                      </v-skeleton-loader>
                                      </v-flex>
                                      <v-spacer></v-spacer>
                                      <v-flex xs2>
                                          <v-skeleton-loader  
                                              max-width="100"
                                              class="mx-auto my-1" 
                                              type="text@1">
                                          </v-skeleton-loader>
                                      </v-flex>
                                  </v-layout>
                              </v-flex>
                              <!-- Image and 3D Model -->
                              <v-flex xs5>
                                  <v-skeleton-loader
                                      class="mx-auto"
                                      height="130"
                                      max-width="200"
                                      :boilerplate="imageCouldntGetLoaded"  
                                      type="image"
                                  ></v-skeleton-loader>
                              </v-flex>
                          </v-layout>
                  </div>
  
              </div>
          </v-card>
      </v-hover>
      <!-- snackbar -->
      <snackbar v-model="snackbar" :key="snackbar.toString()+'snackbar'" :snacktext="snacktext"/>
    </div>
  </template>
  
  <script>
  import snackbar         from  './snackbar'
  import serverGateway    from  '../modules/serverGateway'
  import api              from  '../modules/api'
  export default {
    name: 'showItems',
    components: {  snackbar  },
    props: ['items', 'index'],
    data() {
      return {
          isDialogOpen: false,
          thumbnailSrc: null,
          imageItem: null,
          refreshKey: false,
          error: null,
          imageCouldntGetLoaded: false, 
          snacktext: "Please wait...", 
          snackbar: false
      }
    },
  
    methods: {
  
      async onIntersect(entries){
          this.Intersectflag = entries[0].isIntersecting
          if(this.Intersectflag && Object.keys(this.items[this.index]).length < 2 ) this.loadProduct()
      },
  
      async loadProduct(){
          try {
              this.items[this.index] = await serverGateway.getItemAdmin(this.items[this.index]._id)
              if(this.items[this.index] && this.items[this.index].description){
                  await this.loadThumbnail()
                  this.refreshKey= !this.refreshKey
              }
          } catch (error) {
              this.error    = error+ ' 🤕'
              this.snacktext= this.error
              this.snackbar = true
          }
      },
  
      async loadThumbnail(){
          if(this.items[this.index].image == 'with image'){ 
              this.thumbnailSrc = `${api.baseUrl}thumbnails/${this.items[this.index]._id}.jpeg`
          }else{
              this.imageCouldntGetLoaded = true
          }
      },
    }
  }
  </script>